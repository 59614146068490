import { z } from 'zod'
import { popUpSchema } from './popupSchema.js'
import { CallNotifications } from '../../../constants/callNotifications'
import { ReactionsType } from '../../../constants/reactionsType'

export const reactions = ['heart', 'confetti', 'clapping', 'timer', 'arrow']

export const notificationSchema = z.discriminatedUnion('event', [
  z.object({
    event: z.literal(CallNotifications.LIKE),
  }),
  z.object({
    event: z.literal(CallNotifications.SHOW_POPUP),
    payload: popUpSchema,
  }),
  z.object({
    event: z.literal(CallNotifications.HIDE_POPUP),
  }),
  z.object({
    event: z.literal(CallNotifications.START_EVENT_COUNTDOWN),
    payload: z.object({
      activateStartEventCountdown: z.boolean(),
      secondsToAddStartCountdown: z.number(),
    }),
  }),
  z.object({
    event: z.literal(CallNotifications.FINISH_EVENT_COUNTDOWN),
    payload: z.object({
      activateFinishEventCountdown: z.boolean(),
      secondsToAddFinishCountdown: z.number(),
    }),
  }),
  z.object({
    event: z.literal(CallNotifications.SHOW_QUICK_ADD),
  }),
  z.object({
    event: z.literal(CallNotifications.HIDE_QUICK_ADD),
  }),
  z.object({
    event: z.literal(CallNotifications.SHOW_REACTIONS),
    payload: z.object({
      reaction: z.nativeEnum(ReactionsType),
    }),
  }),
])
