import React from 'react'
import PropTypes from 'prop-types'
import { ChatLoaderContainer, ChatSpinnerContainer } from './style'
import { CircularProgress } from '@material-ui/core'
import { AskJoinChat } from './AskJoinChat'
import { ActionBar } from '../Call/ActionBar'
import { ChatAccordion } from './ChatAccordion'

export const ChatLoader = ({ isDesktop, loading, handleClick }) => {
  return (
    <ChatLoaderContainer isDesktop={isDesktop}>
      <ChatSpinnerContainer loading={loading} isDesktop={isDesktop}>
        {loading ? (
          <CircularProgress
            size={isDesktop ? '2.5rem' : '1.5rem'}
            color={isDesktop ? 'primary' : 'secondary'}
          />
        ) : (
          <>
            {isDesktop ? (
              <AskJoinChat handleClick={handleClick} />
            ) : (
              <ChatAccordion handleClick={handleClick} />
            )}
          </>
        )}
      </ChatSpinnerContainer>
      {!isDesktop && <ActionBar />}
    </ChatLoaderContainer>
  )
}

ChatLoader.propTypes = {
  isDesktop: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}
