import React from 'react'
import PropTypes from 'prop-types'
import { CallHomeContainer } from '../../pages/oneToOneLayout'
import { useSelector } from 'react-redux'
import { selectCurrentStore } from '../../reducers/storeSlice'
import StoreUtils from '../../utils/storeUtils'
import { StyledPhoneContainer } from './SellerNotAvailable.styles'
import { useDevices } from '@gojiraf/responsive'

export const SellerNotAvailableCard = ({ children }) => {
  const { isDesktop } = useDevices()
  const store = useSelector(selectCurrentStore)
  const { backgroundUrlMobile = null, backgroundUrl } = store

  return (
    <CallHomeContainer
      isMobile={!isDesktop}
      style={{
        backgroundImage: `url(${StoreUtils.getBackgroundImage({
          isMobile: !isDesktop,
          backgroundUrlMobile,
          backgroundUrl,
        })})`,
      }}
      data-test="background-image"
    >
      <StyledPhoneContainer>{children}</StyledPhoneContainer>
    </CallHomeContainer>
  )
}

SellerNotAvailableCard.propTypes = {
  children: PropTypes.element,
}
