import { useEffect, useState } from 'react'

export const useShareUrl = (store, utm_medium) => {
  const [urlToShare, setUrlToShare] = useState('')

  useEffect(() => {
    let newUrl
    if (store.storeConfigurations.url) {
      newUrl = new URL(store.storeConfigurations.url)
    } else if (process.env.REACT_APP_DEEP_LINK && store.slug) {
      newUrl = new URL(store.slug, process.env.REACT_APP_DEEP_LINK)
    } else {
      newUrl = new URL(window.location.href)
    }
    newUrl.searchParams.set('utm_medium', utm_medium)
    newUrl.searchParams.set('utm_source', 'gojiraf')
    setUrlToShare(newUrl)
  }, [])

  return {
    urlToShare: urlToShare.toString(),
  }
}
