import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { upsertUser } from '../reducers/callSlice'
import { selectCurrentStore } from '../reducers/storeSlice'
import { useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { useAuth } from '@gojiraf/auth'
import { useViews } from 'state'
import { sendUserData } from '../services/usersService'
import { selectAllowCancelAskNameDialog, setShowAskNameDialog } from '../reducers/uiSlice'
const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const useSendUser = ({ isDesktop, eventId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [nickname, setNickname] = useState('')
  const [showErrorMessageNickname, setShowErrorMessageNickname] = useState(false)
  const [nationalId, setNationalId] = useState('')
  const [errorMessageNationalId, setErrorMessageNationalId] = useState(null)
  const [email, setEmail] = useState('')
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false)
  const [checked, setChecked] = useState(false)
  const [showErrorMessageCheckbox, setShowErrorMessageCheckbox] = useState(false)
  const allowCancel = useSelector(selectAllowCancelAskNameDialog)
  const {
    id: storeId,
    storeConfigurations: {
      features: { chat, chatLogin },
    },
  } = useSelector(selectCurrentStore)
  const { email: emailLogin, nationalId: nationalIdLogin, nickName: nickNameLogin } = chatLogin
  const dispatch = useDispatch()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { sendEventPostToElastic } = useElasticEventTracker()

  const { setUsername, user } = useAuth()
  const { send: sendViews } = useViews()

  const validateNationalId = () => {
    const trimmedNationalId = nationalId?.trim()
    const validNationalIdLength =
      trimmedNationalId.length >= nationalIdLogin.minLength &&
      trimmedNationalId.length <= nationalIdLogin.maxLength

    const isRequired = nationalIdLogin.required
    const errorMessage = !validNationalIdLength
      ? t('chat.nationalIdErrorLength')
      : t('chat.errorMessageRequiredInput')

    const validNationalIdFormat = isRequired
      ? validNationalIdLength
      : trimmedNationalId === '' || validNationalIdLength

    return {
      validNationalIdFormat,
      errorMessage,
    }
  }

  const sendData = async ({ event_id, store_id, name, email, national_id }) => {
    try {
      const response = await sendUserData({ event_id, store_id, name, email, national_id })
      if (response?.data?.errors) {
        const errorMessage = response.data.errors[0]?.message ?? ''
        throw new Error(`Error saving user data ${errorMessage}`)
      }
      sendEventPostToElastic({ event: 'InCall', eventType: `save-user-and-email` })
      matomoTrackEvent('InCall', `save-user-and-email`)
    } catch (error) {
      console.error(error)
      sendEventPostToElastic({ event: 'InCall', eventType: `fail-error-saving-user` })
      matomoTrackEvent('InCall', `fail-error-saving-user`)
      sendEventPostToElastic({ event: 'InCall', eventType: 'fail-save-user-and-email' })
    }
  }

  const handleConfirm = async () => {
    const validNickNameFormat =
      nickname?.trim().length >= nickNameLogin.minLength &&
      nickname?.trim().length <= nickNameLogin.maxLength
    const validEmailFormat =
      (!emailLogin.required && email?.trim() === '') || emailPattern.test(email)
    const { validNationalIdFormat, errorMessage } = validateNationalId()

    if (!validNickNameFormat || !validEmailFormat || !validNationalIdFormat || !checked) {
      if (!validNickNameFormat) {
        setShowErrorMessageNickname(true)
      } else {
        setShowErrorMessageNickname(false)
      }
      if (!validEmailFormat) {
        setShowErrorMessageEmail(true)
      } else {
        setShowErrorMessageEmail(false)
      }
      if (!validNationalIdFormat) {
        setErrorMessageNationalId(errorMessage)
      } else {
        setErrorMessageNationalId(null)
      }
      if (!checked) {
        setShowErrorMessageCheckbox(true)
      } else {
        setShowErrorMessageCheckbox(false)
      }
      return
    }

    setLoading(true)

    let successfullUpsertUser

    if (chat.type === 'gojiraf') {
      successfullUpsertUser = true
    } else {
      const {
        payload: { success },
      } = await dispatch(upsertUser({ ...user, name: nickname }))
      successfullUpsertUser = success
    }

    if (successfullUpsertUser) setUsername(nickname)

    sendEventPostToElastic({ event: 'InCall > Chat', eventType: 'confirm-name-chat' })
    matomoTrackEvent('InCall > Chat', 'confirm-name-chat')

    if ((email.trim() !== '' || nationalId.trim() !== '') && successfullUpsertUser) {
      sendData({
        event_id: eventId,
        store_id: storeId,
        name: nickname,
        email,
        national_id: nationalId.toString(),
      })
    }

    if (isDesktop) {
      handleCloseDialog()
    } else {
      sendViews({ type: 'GO_BACK' })
    }
  }

  const handleCloseDialog = () => {
    matomoTrackEvent('InCall > Chat', 'cancel-name-chat')
    sendEventPostToElastic({ event: 'InCall > Chat', eventType: 'cancel-name-chat' })
    dispatch(setShowAskNameDialog({ open: false }))
  }

  return {
    handleConfirm,
    handleCancel: handleCloseDialog,
    nickname,
    email,
    nationalId,
    loading,
    showErrorMessageCheckbox,
    showErrorMessageEmail,
    showErrorMessageNickname,
    errorMessageNationalId,
    checked,
    setChecked,
    setEmail,
    setNickname,
    setNationalId,
    chatLogin,
    allowCancel,
  }
}
