import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MessagesV2 } from './Messages_v2'
import { ChatInputV2 } from './ChatInput_v2'
import { ChatHeader } from './ChatHeader'
import {
  selectUserCount,
  selectTermsAndConditionsAccepted,
  resetMessagesCount,
} from '../../reducers/callSlice'
import qs from 'qs'
import UtmUtils from '../../utils/utmUtils'
import { useDispatch, useSelector } from 'react-redux'
import { CustomerRoles } from '../../constants/customerRoles'
import { ChatContainer } from './style'
import { selectAutoJoinChat, selectChatMuted, setShowAskNameDialog } from '../../reducers/uiSlice'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { ActionBar } from '../Call/ActionBar'
import { Row } from '../Call/Layout.styles'
import { ChatLoader } from './ChatLoader'
import { ChatAccordion } from './ChatAccordion'
import { DefaultBuyerName } from '@gojiraf/auth'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useUtm } from '@gojiraf/useutm'

export const ChatModuleV2 = ({
  isDesktop = false,
  channelId,
  chatConnected,
  chatOpen,
  setChatOpen,
  loadingChatConnection,
  setLoadingChatConnection,
  messages,
  hasMoreMessages,
  fetchMoreMessages,
  isFetchingMoreMessages,
  sendMessage,
  initChat,
  user,
}) => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const chatMuted = useSelector(selectChatMuted)
  const {
    storeConfigurations: {
      features: {
        chat: { allowCancel, askNameOnCallStart, showMessageField },
      },
    },
  } = useSelector(selectCurrentStore)
  const termsAndConditionsAccepted = useSelector(selectTermsAndConditionsAccepted)
  const usersCount = useSelector(selectUserCount)
  const isModerator = user.role === CustomerRoles.MODERATOR

  useEffect(() => {
    if (!autoJoinChat) setLoadingChatConnection(false)
  }, [autoJoinChat])

  const handleClick = async () => {
    gaEventTracker('InCall > Chat', 'click-button-enabled-chat-d')
    matomoTrackEvent('InCall > Chat', 'click-button-enabled-chat-d')
    setLoadingChatConnection(true)
    await initChat()
  }

  useEffect(() => {
    const askNameForChat = () => {
      if (user.name === DefaultBuyerName && !chatMuted && askNameOnCallStart && showUIComponents) {
        dispatch(
          setShowAskNameDialog({
            open: true,
            allowCancel,
          }),
        )
      }
    }

    if (termsAndConditionsAccepted && chatConnected) askNameForChat()
  }, [termsAndConditionsAccepted, chatConnected])

  const toggleChatMobile = (toggle) => {
    setChatOpen(toggle)
    dispatch(resetMessagesCount())
  }

  return (
    <ChatContainer
      isDesktop={isDesktop}
      isLoading={!chatConnected}
      chatOpen={chatOpen}
      data-test="chat-container"
    >
      {isDesktop === true && <ChatHeader isModerator={isModerator} usersCount={usersCount} />}
      {!chatConnected ? (
        <ChatLoader
          handleClick={handleClick}
          loading={loadingChatConnection}
          isDesktop={isDesktop}
        />
      ) : (
        <>
          <MessagesV2
            isDesktop={isDesktop}
            hasMoreMessages={hasMoreMessages}
            fetchMoreMessages={fetchMoreMessages}
            isFetchingMoreMessages={isFetchingMoreMessages}
            toggleChatMobile={toggleChatMobile}
            user={user}
            channelId={channelId}
            messages={messages}
            chatOpen={chatOpen}
          >
            {!isDesktop && <ChatAccordion chatOpen={chatOpen} />}
          </MessagesV2>
          {(isModerator || showMessageField) && (
            <Row
              style={{ gap: '0.312rem', padding: isDesktop ? '0' : '0 1rem', alignItems: 'center' }}
            >
              <ChatInputV2 isDesktop={isDesktop} sendMessage={sendMessage} />
              {!isDesktop && <ActionBar />}
            </Row>
          )}
        </>
      )}
    </ChatContainer>
  )
}

ChatModuleV2.propTypes = {
  isDesktop: PropTypes.bool,
  chatConnected: PropTypes.bool,
  chatOpen: PropTypes.bool,
  setChatOpen: PropTypes.func,
  channelId: PropTypes.string,
  loadingChatConnection: PropTypes.bool,
  setLoadingChatConnection: PropTypes.func,
  initChat: PropTypes.func,
  sendMessage: PropTypes.func,
  messages: PropTypes.array,
  hasMoreMessages: PropTypes.bool,
  fetchMoreMessages: PropTypes.func,
  isFetchingMoreMessages: PropTypes.bool,
  user: PropTypes.object.isRequired,
}
