import React from 'react'
import PropTypes from 'prop-types'
import { ActionBar } from './ActionBar'
import { CallAction } from './CallAction'
import { CallActionV2 } from './CallAction_v2'

const CallBottomContainer = ({ chatType, onCallFinished, isDesktop }) => {
  return (
    <>
      {isDesktop ? (
        <ActionBar />
      ) : chatType === 'gojiraf' ? (
        <CallActionV2 onCallFinished={onCallFinished} />
      ) : (
        <CallAction onCallFinished={onCallFinished} />
      )}
    </>
  )
}

CallBottomContainer.propTypes = {
  onCallFinished: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  chatType: PropTypes.string,
}

export { CallBottomContainer }
