import React, { useState } from 'react'
import {
  MessagesContainer,
  MessagesFlexContainer,
  MessagesWrapper,
  MobileChatOverlay,
} from './style'
import PropTypes from 'prop-types'
import { PinnedMessage } from './messages/PinnedMessage'
import { ModeratorMenuOptions } from '../GJKit/Moderator/ModeratorMenuOptions'
import { useModeratorMenu } from '../../hooks/useModeratorMenu'
import { VirtualizedMessageListV2 } from './VirtualizedMessageList_v2'

const MessagesV2 = ({
  isDesktop = false,
  chatOpen,
  user,
  channelId,
  children,
  messages,
  hasMoreMessages,
  fetchMoreMessages,
  toggleChatMobile,
  isFetchingMoreMessages,
}) => {
  const [isGestureLocked, setIsGestureLocked] = useState(false)
  const [startY, setStartY] = useState(0)
  const [endY, setEndY] = useState(0)
  const { showMenuOptions, handleShowModeratorMenu } = useModeratorMenu(user)
  const { messages: messagesList, pinnedMessage } = messages

  const unlockGesture = () => {
    setTimeout(() => {
      setIsGestureLocked(false)
    }, 300)
  }

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY)
  }

  const handleTouchMove = (e) => {
    if (isGestureLocked) return
    setEndY(e.touches[0].clientY)
    const deltaY = endY - startY

    if (deltaY > 50 && chatOpen) {
      toggleChatMobile(false)
      setIsGestureLocked(true)
      unlockGesture()
    } else if (deltaY < -50 && !chatOpen) {
      toggleChatMobile(true)
      setIsGestureLocked(true)
      unlockGesture()
    }
  }

  return (
    <>
      <MessagesWrapper>
        {!isDesktop && (
          <MobileChatOverlay
            onClick={() => toggleChatMobile(!chatOpen)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {children}
          </MobileChatOverlay>
        )}
        <MessagesFlexContainer isDesktop={isDesktop}>
          <MessagesContainer isDesktop={isDesktop} data-test="messages-container">
            {(messagesList.length === 0 && !isDesktop) || !chatOpen ? null : (
              <>
                <VirtualizedMessageListV2
                  messages={messagesList}
                  hasMoreMessages={hasMoreMessages}
                  fetchMoreMessages={fetchMoreMessages}
                  isFetchingMoreMessages={isFetchingMoreMessages}
                  user={user}
                  isDesktop={isDesktop}
                />
                {pinnedMessage && (
                  <PinnedMessage
                    message={pinnedMessage}
                    user={user}
                    handleShowModeratorMenu={handleShowModeratorMenu}
                    isDesktop={isDesktop}
                  />
                )}

                {showMenuOptions && (
                  <ModeratorMenuOptions
                    isDesktop={isDesktop}
                    handleClick={handleShowModeratorMenu}
                    channelId={channelId}
                  />
                )}
              </>
            )}
          </MessagesContainer>
        </MessagesFlexContainer>
      </MessagesWrapper>
    </>
  )
}

MessagesV2.propTypes = {
  isDesktop: PropTypes.bool,
  chatOpen: PropTypes.bool,
  isFetchingMoreMessages: PropTypes.bool,
  user: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  children: PropTypes.element,
  hasMoreMessages: PropTypes.bool,
  fetchMoreMessages: PropTypes.func,
  toggleChatMobile: PropTypes.func,
  messages: PropTypes.array,
}

export { MessagesV2 }
