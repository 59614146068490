export default () => {
  return {
    message: {
      paddingTop: '30px',
    },
    dialog__actions: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
}
