import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useViews } from 'state'
import { useAuth } from '@gojiraf/auth'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { LaptopCameras } from '../GJKit/Cameras/LaptopCameras'
import { ChatModule } from './ChatModule'
import { useChat } from '../../hooks/useChat'
import {
  selectActiveCallAction,
  CALL_ACTION_CAMERAS,
  selectAutoJoinChat,
} from '../../reducers/uiSlice'
import { ChatDesktopContainer } from './style'
import CallService from '../../services/callService'
import { selectIsOnCall, selectSeller } from '../../reducers/callSlice'
import AskNameDialog from '../dialogs/AskNameDialog'

const ChatDesktop = ({ toggleMicrophone, toggleCamera }) => {
  const { user } = useAuth()
  const activeCard = useSelector(selectActiveCallAction)
  const { id: sellerId } = useSelector(selectSeller)
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const isOnCall = useSelector(selectIsOnCall)
  const getChannelId = async () => await CallService.findEventId(sellerId)
  const { initChat, chatData, channelDeleted, eventId } = useChat({
    user,
    autoJoinChat,
    isOnCall,
    getChannelId,
  })
  const { state, send } = useViews()
  const { isOneToManySale, storeConfigurations } = useSelector(selectCurrentStore)

  useEffect(() => {
    if (activeCard === CALL_ACTION_CAMERAS) {
      send({ type: 'SHOW_PARTICIPANTS' })
    } else {
      send({ type: 'SHOW_CHAT' })
    }
  }, [])

  return (
    <ChatDesktopContainer data-test="chat-desktop-container">
      {!isOneToManySale && (
        <LaptopCameras
          style={{ display: state.matches('secondary.showingParticipants') === true ? '' : 'none' }}
          allowsMultipleBuyers={storeConfigurations.allowsMultipleBuyers}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
        />
      )}
      {state.matches('secondary.showingChat') === true && (
        <ChatModule
          isDesktop
          channelDeleted={channelDeleted}
          chatData={chatData}
          initChat={initChat}
          user={user}
        />
      )}
      <AskNameDialog eventId={eventId} />
    </ChatDesktopContainer>
  )
}

ChatDesktop.propTypes = {
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
}

export { ChatDesktop }
