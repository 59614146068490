export const ChatErrors = {
  MutedByAdmin: 17,
  RateLimits: 9,
  BadWord: 99,
}

export const MessageTypes = {
  error: 'error',
  regular: 'regular',
}
