import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAutoJoinChat } from '../reducers/uiSlice'
import ChatService from '../services/chatService'
import { useGoogleAnalytics, useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { ChatErrors } from '../constants/chatErrors'
const chatDataInitialState = {
  chatClient: null,
  chatChannel: null,
}

export const useChat = ({ user, isOnCall, autoJoinChat, getChannelId }) => {
  const [chatData, setChatData] = useState(chatDataInitialState)
  const [eventId, setEventId] = useState(null)
  const [channelDeleted, setChannelDeleted] = useState(false)
  const dispatch = useDispatch()
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { sendEventPostToElastic } = useElasticEventTracker()

  const initChat = async () => {
    if (isOnCall) {
      try {
        const channelId = await getChannelId()
        const { client, channel } = await ChatService.joinChat({
          user,
          channelId,
        })
        setEventId(channelId)
        setChatData({ chatClient: client, chatChannel: channel })
        return { successfullConnection: true }
      } catch (error) {
        const rateLimitsError = error.code === ChatErrors.RateLimits
        console.error(error)
        await ChatService.sendErrorMetricsJoinChat({
          user,
          errorType: rateLimitsError ? `rate-limits-at-initChat` : `unexpected-at-initChat`,
          errorCode: error.code?.toString() ?? '500',
        })
        if (!rateLimitsError) dispatch(setAutoJoinChat(false))
        return { successfullConnection: rateLimitsError }
      }
    }
  }

  useEffect(() => {
    if (autoJoinChat) initChat()
    const handleChannelDeleted = () => {
      setChannelDeleted(true)
      dispatch(setAutoJoinChat(false))
      gaEventTracker('Chat-ChatService', 'deleted-chat-channel')
      sendEventPostToElastic({
        event: 'errors',
        eventType: 'deleted-chat-channel',
      })
      matomoTrackEvent('Chat-ChatService', 'deleted-chat-channel')
    }

    const unsubscribeChannelDeleted = ChatService.listenChannelDeleted(handleChannelDeleted)

    return () => {
      unsubscribeChannelDeleted()
    }
  }, [])

  useEffect(() => {
    if (!autoJoinChat && chatData.chatClient && chatData.chatChannel) {
      setChatData(chatDataInitialState)
    }
  }, [autoJoinChat])

  return {
    chatData,
    eventId,
    initChat,
    channelDeleted,
  }
}
