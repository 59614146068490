import React from 'react'
import PropTypes from 'prop-types'
import StoreUtils from '../../utils/storeUtils'
import UtmUtils from '../../utils/utmUtils'
import styled, { css, keyframes } from 'styled-components'
import { useProducts, usePDP, FeatureProduct } from 'ui'
import { useDispatch, useSelector } from 'react-redux'
import { useViews } from 'state'
import { StoreInfoFloating } from '../storeInfoFloating'
import { CallBottomContainer } from './BottomContainer'
import { LocalMediaControls } from '../GJKit/LocalMedia/LocalMediaControls'
import { selectCurrentStore } from '../../reducers/storeSlice'
import {
  selectEventStartServerDateTime,
  selectFinishEventServerDateTime,
  selectShowStoreInfo,
} from '../../reducers/uiSlice'
import { EventTimer } from '../Countdown/EventTimer'
import { ExternalReactions } from '../Call/ExternalReactions'
import { CustomerRoles } from '../../constants/customerRoles'
import { useFinishEventCountdown } from '../../hooks/useFinishEventCountdown'
import { useStartEventCountdown } from '../../hooks/useStartEventCountdown'
import { BaseCallLayout, MainContent, Row } from './Layout.styles'
import { TopNavBar } from './TopNavBar'
import { PopUp } from '../GJKit/PopUp'
import { useAuth } from '@gojiraf/auth'
import { useGoogleAnalytics, useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { leaveCall, setDisplayStartEventCountdown } from '../../reducers/callSlice'
import { EventLogs } from '../../constants/eventLogs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CartCount } from 'ui'
import qs from 'qs'
import { RoundedButton } from '../Kit/Buttons'
import { Cameras } from '../GJKit/Cameras/Cameras'
import { useDevices } from '@gojiraf/responsive'
import { useCart } from 'ui'
import { Carousel, CarouselContent, CarouselItem } from 'ui'
import { useUtm } from '@gojiraf/useutm'
import QuickAddButton from './QuickAddButton'

const CallLayout = ({ onCallFinished, children, toggleMicrophone, toggleCamera, popUpData }) => {
  const { t } = useTranslation()
  const { isDesktop } = useDevices()
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { sendEventPostToElastic } = useElasticEventTracker()
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)
  const { displayFinishEventCountdown, dispatchFinishCountdown } = useFinishEventCountdown()
  const { displayStartEventCountdown, dispatchStartCountdown } = useStartEventCountdown()
  const dispatch = useDispatch()
  const showStoreInfo = useSelector(selectShowStoreInfo)
  const finishEventServerDateTime = useSelector(selectFinishEventServerDateTime)
  const startEventServerDateTime = useSelector(selectEventStartServerDateTime)
  const { featuredProduct, animateFeaturedProduct, products } = useProducts()
  const store = useSelector(selectCurrentStore)
  const { isOneToManySale, storeConfigurations } = store
  const { user } = useAuth()
  const isModerator = user.role === CustomerRoles.MODERATOR
  const location = useLocation()
  const isCohostUser = location.state?.isCohostUser
  const { state, send } = useViews()
  const { send: sendPDP } = usePDP()
  const isLightIntegration = StoreUtils.getIsLightIntegration(store.paymentGateways)
  const featuredProductHasVariants = featuredProduct?.variants?.length > 0
  const { send: cartSend } = useCart()
  const renderCameras = !isOneToManySale && state.matches('secondary.showingParticipants') === true

  const handleProductClicked = (product) => {
    if (product?.variants?.length > 0 || isLightIntegration) {
      gaEventTracker(
        'InCall > Products',
        product.main === true
          ? `featured-product [${product.name}]`
          : `click-product-detail-button [${product.name}]`,
      )
      matomoTrackEvent(
        'InCall > Products',
        product.main === true
          ? `featured-product [${product.name}]`
          : `click-product-detail-button [${product.name}]`,
      )
      sendEventPostToElastic({
        event: 'products',
        eventType: product.main === true ? 'featured-product' : 'click-product-detail-button',
        product: product.name,
      })
      sendPDP({
        type: 'SET_PRODUCT',
        productId: product.id,
      })
      send({
        type: 'SHOW_PDP',
      })
    } else {
      gaEventTracker('InCall > Products', 'cart-initialized')
      matomoTrackEvent('InCall > Products', 'cart-initialized')
      sendEventPostToElastic({
        event: 'InCall > Products',
        eventType: 'cart-initialized',
      })
      cartSend({
        type: 'Add product variant',
        productVariantId: product.skus[0].id,
        quantity: 1,
      })
      send({
        type: 'SHOW_CART',
      })
    }
  }

  return (
    <BaseCallLayout
      isDesktop={isDesktop}
      CamerasAndProducts={renderCameras || !!featuredProduct}
      data-test="landing-OTM"
    >
      <ExternalReactions />
      {showStoreInfo && showUIComponents && (
        <Row>
          <StoreInfoFloating />
          {!isDesktop ? (
            <TopNavBar
              isModerator={isModerator}
              dispatchFinishCountdown={dispatchFinishCountdown}
              dispatchStartCountdown={dispatchStartCountdown}
              onCallFinished={onCallFinished}
            />
          ) : null}
        </Row>
      )}
      <Row>
        <PopUp data={popUpData} />

        {(!isDesktop || isCohostUser) && showUIComponents && (
          <CartAndMediaControlsContainer>
            {!isLightIntegration && !isDesktop ? (
              state.matches('secondary.showingCart') === true ? (
                <RoundedButton filled layout>
                  <CartCount className="text-white bg-white fill-black" />
                </RoundedButton>
              ) : (
                <RoundedButton filled layout>
                  <CartCount className="text-black" />
                </RoundedButton>
              )
            ) : null}
            {(!isOneToManySale || isCohostUser) && (
              <LocalMediaControls
                layout
                data-test="local-media-container"
                isCohostUser={isCohostUser}
                isMobile={!isDesktop}
                toggleMicrophone={toggleMicrophone}
                toggleCamera={toggleCamera}
              />
            )}
          </CartAndMediaControlsContainer>
        )}
      </Row>

      {showUIComponents && featuredProduct && (
        <QuickAddButton featuredProduct={featuredProduct} store={store} />
      )}

      {displayFinishEventCountdown && (
        <EventTimer
          title={t('countdown.finishEventTimerHeader')}
          subtitle={t('countdown.finishEventTimerSecondaryHeader')}
          dateTime={finishEventServerDateTime}
          onTimeUp={() => {
            dispatch(leaveCall())
            onCallFinished(EventLogs.SELLER_HANG_UP_ALL)
          }}
        />
      )}
      {displayStartEventCountdown && (
        <EventTimer
          title={t('countdown.startEventTimerHeader')}
          dateTime={startEventServerDateTime}
          onTimeUp={() => {
            gaEventTracker('InCall', `post-counter-event-entry`)
            matomoTrackEvent('InCall', `post-counter-event-entry`)
            dispatch(setDisplayStartEventCountdown(false))
          }}
        />
      )}

      <MainContent>{children}</MainContent>

      {featuredProduct && isDesktop && showUIComponents && (
        <ProductChangedAnimation featuredProductChanged={animateFeaturedProduct}>
          <FeatureProduct
            propText={
              !featuredProductHasVariants && !isLightIntegration ? t('products.addToCart') : ''
            }
            product={featuredProduct}
            onProductClicked={handleProductClicked}
          />
        </ProductChangedAnimation>
      )}

      {showUIComponents && (
        <CallBottomContainer
          isDesktop={isDesktop}
          chatType={storeConfigurations.features.chat.type}
          onCallFinished={onCallFinished}
          data-test="mobile-action-bar-container"
        />
      )}

      {!isDesktop && showUIComponents && (
        <CamerasAndFeaturedProductContainer>
          {renderCameras ? (
            <Cameras allowsMultipleBuyers={storeConfigurations.allowsMultipleBuyers} />
          ) : featuredProduct ? (
            <>
              {products?.length > 1 ? (
                <Carousel
                  orientation="horizontal"
                  className="size-full"
                  featuredProductId={featuredProduct.id}
                >
                  <CarouselContent className="flex">
                    {products.map((product, index) => (
                      <CarouselItem
                        key={index}
                        featuredProductId={featuredProduct.id}
                        productsList={products}
                      >
                        <FeatureProduct
                          propText={
                            !product?.variants?.length > 0 && !isLightIntegration
                              ? t('products.addToCart')
                              : ''
                          }
                          product={product}
                          onProductClicked={handleProductClicked}
                        />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                </Carousel>
              ) : (
                <ProductChangedAnimation featuredProductChanged={animateFeaturedProduct}>
                  <FeatureProduct
                    propText={
                      !featuredProductHasVariants && !isLightIntegration
                        ? t('products.addToCart')
                        : ''
                    }
                    product={featuredProduct}
                    onProductClicked={handleProductClicked}
                  />
                </ProductChangedAnimation>
              )}
            </>
          ) : null}
        </CamerasAndFeaturedProductContainer>
      )}
    </BaseCallLayout>
  )
}

CallLayout.propTypes = {
  children: PropTypes.element,
  onCallFinished: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
  popUpData: PropTypes.object,
}

const scaleInCenter = keyframes`
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`

const ProductChangedAnimation = styled.div`
  z-index: 1;
  padding: 0 1rem 1rem 1rem;
  animation: ${({ featuredProductChanged }) =>
    featuredProductChanged
      ? css`
          ${scaleInCenter} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
        `
      : 'none'};
  width: 100%;
  border-radius: 0.5rem;
`

const CartAndMediaControlsContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const CamerasAndFeaturedProductContainer = styled.div`
  z-index: 1;
`

export { CallLayout }
