import React from 'react'
import PropTypes from 'prop-types'
import { Icons } from 'icons'
import { useSelector } from 'react-redux'
import { ChatBubbleOutline } from '@material-ui/icons'
import { ButtonBubble } from '../Call/ButtonBubble'
import { selectMessagesCount } from '../../reducers/callSlice'
import { ChatAccordionArrow, ChatAccordionButton } from './style'

export const ChatAccordion = ({ handleClick, chatOpen }) => {
  const messagesCount = useSelector(selectMessagesCount)

  return (
    <ChatAccordionButton type="button" onClick={() => handleClick(!chatOpen)}>
      {messagesCount && !chatOpen ? (
        <ButtonBubble badgeContent={messagesCount} color="error">
          <ChatBubbleOutline />
        </ButtonBubble>
      ) : (
        <ChatBubbleOutline />
      )}
      <span>Chat</span>
      <ChatAccordionArrow rotate={chatOpen}>
        <Icons.Expand color="white" />
      </ChatAccordionArrow>
    </ChatAccordionButton>
  )
}

ChatAccordion.propTypes = {
  chatOpen: PropTypes.bool,
  handleClick: PropTypes.func,
}
