import styled, { css } from 'styled-components'

export const MainHeading = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  line-height: 0.75rem;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      font-size: 1.5rem;
      font-weight: 500;
    `}
`
