import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDisplayFinishEventCountdownstate,
  setDisplayFinishEventCountdown,
} from '../reducers/callSlice'
import { selectCurrentStore, setFinishEventCountdown } from '../reducers/storeSlice'
import { setFinishEventServerDateTime } from '../reducers/uiSlice'
import { debounce } from 'lodash'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { getDateTime } from '../utils/countdownTimerUtils'

export const useFinishEventCountdown = () => {
  const {
    id: storeId,
    storeConfigurations: {
      features: {
        finishEvent: {
          activateFinishEventCountdown,
          secondsToFinishEvent,
          remainingSecondsToFinishEvent,
        },
      },
    },
  } = useSelector(selectCurrentStore)
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const displayFinishEventCountdown = useSelector(selectDisplayFinishEventCountdownstate)

  const dispatchFinishCountdown = debounce(async () => {
    gaEventTracker('Moderator', 'click-switch-finish-event-countdown')
    matomoTrackEvent('Moderator', 'click-switch-finish-event-countdown')
    dispatch(setFinishEventServerDateTime(getDateTime(secondsToFinishEvent)))
    // dispatch(notifyFinishEventCountdownMessage(!displayFinishEventCountdown))  //Se comenta porque se elimina el método en CallService al no utilizar más el canal RTM de agora. No se eliminar el archivo porque requiere otro análisis.
    dispatch(
      setFinishEventCountdown({
        storeId,
        activateFinishEventCountdown: !displayFinishEventCountdown,
        seconds: 600,
      }),
    )
  }, 1000)

  useEffect(() => {
    if (activateFinishEventCountdown && remainingSecondsToFinishEvent > 0) {
      dispatch(setFinishEventServerDateTime(getDateTime(remainingSecondsToFinishEvent)))
      dispatch(setDisplayFinishEventCountdown(true))
    }
  }, [])

  return { displayFinishEventCountdown, dispatchFinishCountdown }
}
