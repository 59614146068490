import { v4 as uuidv4 } from 'uuid'
import store from '../../core/store'
import { setCheckoutFinishedSuccess } from '../../reducers/orderSlice'

const Natura = function (paymentGateway) {
  this.paymentGateway = paymentGateway
  this.quote = null
  this.checkout = null
  this.orderId = null
  this.products = []

  this.createQuote = ({ order, productsList }) => {
    this.quote = order.orderDetails.map((orderDetail) => {
      return {
        upc: orderDetail.product.sku,
        oid: orderDetail.product.externalId,
        q: orderDetail.product.quantity,
        qu: productsList.find((product) =>
          product.skus.some((sku) => sku.id == orderDetail.product.skuId),
        ).unit,
      }
    })
  }

  this.startCheckout = async () => null

  this.goToCheckout = ({ checkoutWindow }) => {
    const shoppableAdsCartValue = {
      rid: uuidv4(),
      cd: this.quote,
    }

    const base64CartValue = btoa(
      unescape(encodeURIComponent(JSON.stringify(shoppableAdsCartValue))),
    )

    const checkoutUrl = new URL('/cart', this.paymentGateway.urlCheckout)

    checkoutUrl.searchParams.set('ad_source', 'socialmtz')
    checkoutUrl.searchParams.set('shoppableAdsCartValue', base64CartValue)
    if (this.paymentGateway.campaignId) {
      checkoutUrl.searchParams.set('ad_campaign_id', this.paymentGateway.campaignId)
    }
    if (this.paymentGateway.adMedium) {
      checkoutUrl.searchParams.set('ad_medium', this.paymentGateway.adMedium)
    }

    checkoutUrl.searchParams.set('utm_source', 'gojiraf')
    checkoutUrl.searchParams.set('utm_medium', 'participant')
    checkoutUrl.searchParams.set('utm_campaign', 'socios')

    checkoutWindow.location.href = checkoutUrl.toString()
    checkoutWindow.focus()
    store.dispatch(setCheckoutFinishedSuccess(true))
  }
}

export default Natura
