import React from 'react'
import { useChannelStateContext, VirtualizedMessageList } from 'stream-chat-react'
import { MessagesContainer } from './style'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectPinnedMessage } from '../../reducers/uiSlice'
import { ChatMessage } from './ChatMessage'
import { PinnedMessage } from './messages/PinnedMessage'
import { ModeratorMenuOptions } from '../GJKit/Moderator/ModeratorMenuOptions'
import { useModeratorMenu } from '../../hooks/useModeratorMenu'

const Messages = ({ isDesktop = false, chatOpen, user, channelId }) => {
  const pinnedMessage = useSelector(selectPinnedMessage)
  const { messages } = useChannelStateContext()
  const { showMenuOptions, handleShowModeratorMenu } = useModeratorMenu(user)

  return (
    <>
      {(messages.length === 0 && !isDesktop) || !chatOpen ? null : (
        <MessagesContainer isDesktop={isDesktop} data-test="messages-container">
          <VirtualizedMessageList
            Message={() =>
              ChatMessage({
                isDesktop,
                user,
              })
            }
          />
          {pinnedMessage && (
            <PinnedMessage
              message={pinnedMessage}
              user={user}
              handleShowModeratorMenu={handleShowModeratorMenu}
              isDesktop={isDesktop}
            />
          )}

          {showMenuOptions && (
            <ModeratorMenuOptions
              isDesktop={isDesktop}
              handleClick={handleShowModeratorMenu}
              channelId={channelId}
            />
          )}
        </MessagesContainer>
      )}
    </>
  )
}

Messages.propTypes = {
  isDesktop: PropTypes.bool,
  chatOpen: PropTypes.bool,
  user: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
}

export { Messages }
