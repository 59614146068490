import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MessageInput, Window, Thread, Channel, Chat } from 'stream-chat-react'
import { Messages } from './Messages'
import { ChatInput } from './ChatInput'
import { ChatHeader } from './ChatHeader'
import {
  selectUserCount,
  resetMessagesCount,
  selectTermsAndConditionsAccepted,
} from '../../reducers/callSlice'
import qs from 'qs'
import UtmUtils from '../../utils/utmUtils'
import { useDispatch, useSelector } from 'react-redux'
import { CustomerRoles } from '../../constants/customerRoles'
import { ChatContainer, MobileChatOverlay } from './style'
import { ScrollToBottom } from './buttons/ScrollToBottom'
import { selectAutoJoinChat, selectChatMuted, setShowAskNameDialog } from '../../reducers/uiSlice'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { ActionBar } from '../Call/ActionBar'
import { Row } from '../Call/Layout.styles'
import { ChatLoader } from './ChatLoader'
import { ChatAccordion } from './ChatAccordion'
import { DefaultBuyerName } from '@gojiraf/auth'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useUtm } from '@gojiraf/useutm'

export const ChatModule = ({ isDesktop = false, chatData, channelDeleted, initChat, user }) => {
  const { chatClient, chatChannel } = chatData
  const [isGestureLocked, setIsGestureLocked] = useState(false)
  const [startY, setStartY] = useState(0)
  const [endY, setEndY] = useState(0)
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const chatMuted = useSelector(selectChatMuted)
  const {
    storeConfigurations: {
      features: {
        chat: { allowCancel, askNameOnCallStart, showMessageField },
      },
    },
  } = useSelector(selectCurrentStore)
  const termsAndConditionsAccepted = useSelector(selectTermsAndConditionsAccepted)
  const [chatOpen, setChatOpen] = useState(true)
  const [loading, setLoading] = useState(true)
  const usersCount = useSelector(selectUserCount)
  const isModerator = user.role === CustomerRoles.MODERATOR

  useEffect(() => {
    if (!autoJoinChat) setLoading(false)
    return () => {
      dispatch(resetMessagesCount())
    }
  }, [autoJoinChat])

  const handleClick = async () => {
    gaEventTracker('InCall > Chat', 'click-button-enabled-chat-d')
    matomoTrackEvent('InCall > Chat', 'click-button-enabled-chat-d')
    setLoading(true)
    const { successfullConnection } = await initChat()
    if (!successfullConnection) setLoading(false)
  }

  useEffect(() => {
    const askNameForChat = () => {
      if (user.name === DefaultBuyerName && !chatMuted && askNameOnCallStart && showUIComponents) {
        dispatch(
          setShowAskNameDialog({
            open: true,
            allowCancel,
          }),
        )
      }
    }

    if (termsAndConditionsAccepted && chatClient && !channelDeleted) askNameForChat()
  }, [termsAndConditionsAccepted, chatClient])

  const toggleChatMobile = (toggle) => {
    setChatOpen(toggle)
    dispatch(resetMessagesCount())
  }

  const unlockGesture = () => {
    setTimeout(() => {
      setIsGestureLocked(false)
    }, 300)
  }

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY)
  }

  const handleTouchMove = (e) => {
    if (isGestureLocked) return
    setEndY(e.touches[0].clientY)
    const deltaY = endY - startY

    if (deltaY > 50 && chatOpen) {
      toggleChatMobile(false)
      setIsGestureLocked(true)
      unlockGesture()
    } else if (deltaY < -50 && !chatOpen) {
      toggleChatMobile(true)
      setIsGestureLocked(true)
      unlockGesture()
    }
  }

  return (
    <ChatContainer
      isDesktop={isDesktop}
      isLoading={!chatClient || channelDeleted}
      chatOpen={chatOpen}
      data-test="chat-container"
    >
      {isDesktop === true && <ChatHeader isModerator={isModerator} usersCount={usersCount} />}

      {!chatClient || channelDeleted ? (
        <ChatLoader handleClick={handleClick} loading={loading} isDesktop={isDesktop} />
      ) : (
        <>
          <Chat client={chatClient}>
            <Channel
              MessageNotification={ScrollToBottom}
              channel={chatChannel}
              EmptyStateIndicator="thread"
              TypingIndicator={() => null}
            >
              <Window>
                {!isDesktop && (
                  <MobileChatOverlay
                    onClick={() => toggleChatMobile(!chatOpen)}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                  >
                    <ChatAccordion chatOpen={chatOpen} />
                  </MobileChatOverlay>
                )}
                <Messages
                  isDesktop={isDesktop}
                  user={user}
                  chatOpen={chatOpen}
                  channelId={chatChannel.id}
                />

                <MessageInput Input={() => null} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
          {(isModerator || showMessageField) && (
            <Row
              style={{ gap: '0.312rem', padding: isDesktop ? '0' : '0 1rem', alignItems: 'center' }}
            >
              <ChatInput isDesktop={isDesktop} channelId={chatChannel.id} />
              {!isDesktop && <ActionBar />}
            </Row>
          )}
        </>
      )}
    </ChatContainer>
  )
}

ChatModule.propTypes = {
  isDesktop: PropTypes.bool,
  chatData: PropTypes.object.isRequired,
  initChat: PropTypes.func,
  channelDeleted: PropTypes.bool,
  user: PropTypes.object.isRequired,
}
