import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Cancel, Confirm, JoinChatContainer, Title } from '../dialogs/style'
import { JoinChatInput } from './JoinChatInput'
import { JoinChatCheckbox } from './JoinChatCheckbox'
import { useSendUser } from '../../hooks/useSendUser'
import { CircularProgress } from '@material-ui/core'
import { ConfirmButtonContainer } from './style'

export const JoinChatForm = ({ isDesktop = false, eventId }) => {
  const { t } = useTranslation()
  const {
    handleConfirm,
    handleCancel,
    nickname,
    email,
    nationalId,
    loading,
    showErrorMessageCheckbox,
    showErrorMessageEmail,
    showErrorMessageNickname,
    errorMessageNationalId,
    checked,
    setChecked,
    setEmail,
    setNickname,
    setNationalId,
    chatLogin,
    allowCancel,
  } = useSendUser({ isDesktop, eventId })
  const { email: emailLogin, nickName: nickNameLogin, nationalId: nationalIdLogin } = chatLogin
  const getLabel = (inputData, defaultLabel) => {
    const currentLabel = inputData.label ?? defaultLabel
    if (inputData.required) return currentLabel
    return currentLabel.concat(t('chat.inputOptionalText'))
  }

  return (
    <JoinChatContainer isDesktop={isDesktop}>
      <Title data-test="askName-title" isDesktop={isDesktop}>
        {t('chat.contentTitle')}
      </Title>
      <JoinChatInput
        id="name-input"
        dataTest="askName-input"
        inputLabel={nickNameLogin.label ?? t('chat.inputNicknamePlaceholder')}
        errorMessage={t('homePage.lengthErrorName')}
        data={nickname}
        setData={setNickname}
        showErrorMessage={showErrorMessageNickname}
      />
      {emailLogin.visible && (
        <JoinChatInput
          id="email-input"
          dataTest="askEmail-input"
          inputLabel={getLabel(emailLogin, t('chat.inputEmailPlaceholder'))}
          errorMessage={
            email.trim() === '' ? t('chat.errorMessageRequiredInput') : t('chat.formatErrorEmail')
          }
          data={email}
          setData={setEmail}
          showErrorMessage={showErrorMessageEmail}
        />
      )}
      {nationalIdLogin.visible && (
        <JoinChatInput
          id="nationalId-input"
          dataTest="askNationalId-input"
          inputType={nationalIdLogin.dataType}
          inputLabel={getLabel(nationalIdLogin ?? t('chat.inputNationalIdPlaceholder'))}
          errorMessage={errorMessageNationalId}
          data={nationalId}
          setData={setNationalId}
          showErrorMessage={!!errorMessageNationalId}
        />
      )}

      <JoinChatCheckbox
        checked={checked}
        setChecked={() => setChecked(!checked)}
        showErrorMessage={showErrorMessageCheckbox}
        errorMessage={t('chat.errorCheckbox')}
      />
      <ConfirmButtonContainer>
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <Confirm
            id="accept-chat-button"
            data-test="askName-confirm"
            variant="contained"
            onClick={handleConfirm}
          >
            {t('chat.buttonConfirm').toUpperCase()}
          </Confirm>
        )}
      </ConfirmButtonContainer>

      {isDesktop && allowCancel ? (
        <Cancel
          id="cancel-chat-button"
          data-test="askName-cancel"
          disable={loading}
          onClick={handleCancel}
        >
          {t('chat.buttonCancel')}
        </Cancel>
      ) : null}
    </JoinChatContainer>
  )
}

JoinChatForm.propTypes = {
  isDesktop: PropTypes.bool,
  upsertUserV2: PropTypes.func,
  eventId: PropTypes.string,
}
