import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './style'
import { useSelector } from 'react-redux'
import { selectShowAskNameDialog } from '../../reducers/uiSlice'
import { JoinChatForm } from '../Chat/JoinChatForm'

const AskNameDialog = ({ eventId }) => {
  const askNameDialogIsVisible = useSelector(selectShowAskNameDialog)

  return (
    <Container data-test="askName-container" open={askNameDialogIsVisible ?? false}>
      <JoinChatForm isDesktop eventId={eventId} />
    </Container>
  )
}

AskNameDialog.propTypes = {
  eventId: PropTypes.string,
}

export default AskNameDialog
