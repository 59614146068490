import React from 'react'
import PropTypes from 'prop-types'
import { CustomButton } from './CustomButton'
import { ButtonsContainer } from './SellerNotAvailable.styles'
import { useTranslation } from 'react-i18next'

export const GoToHomeButton = ({ url, text }) => {
  const { t } = useTranslation()

  return (
    <ButtonsContainer minHeight={'auto'}>
      <CustomButton
        onClick={() => {
          window.open(url, '_self')
        }}
      >
        {text || t('homePage.sellerNotAvailable.goToHome')}
      </CustomButton>
    </ButtonsContainer>
  )
}

GoToHomeButton.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
}
