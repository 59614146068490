import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { publish } from '../../../graphql/mutations'
import { subscribe } from '../../../graphql/subscriptions'
import { stringToJSONSchema } from './schemas/stringToJSONSchema'
import { notificationSchema } from './schemas/notificationSchema'

const {
  REACT_APP_NOTIFICATIONS_AWS_ENDPOINT,
  REACT_APP_NOTIFICATIONS_AWS_REGION,
  REACT_APP_NOTIFICATIONS_AWS_API_KEY,
} = process.env

// Configuración de Amplify.
Amplify.configure({
  API: {
    GraphQL: {
      endpoint: REACT_APP_NOTIFICATIONS_AWS_ENDPOINT,
      region: REACT_APP_NOTIFICATIONS_AWS_REGION,
      defaultAuthMode: 'apiKey',
      apiKey: REACT_APP_NOTIFICATIONS_AWS_API_KEY,
    },
  },
})

const client = generateClient()

class NotificationsService {
  constructor(channel) {
    if (NotificationsService.instance) {
      return NotificationsService.instance
    }
    this.channel = channel
    this.subscription = null
    NotificationsService.instance = this
  }

  static getInstance(channel) {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService(channel)
    }
    return NotificationsService.instance
  }

  async sendMessage(message) {
    await client.graphql({
      query: publish,
      variables: { name: this.channel, data: JSON.stringify(message) },
    })
  }

  subscribe(onNotificationReceived) {
    this.subscription = client
      .graphql({ query: subscribe, variables: { name: this.channel } })
      .subscribe({
        next: ({ data }) => {
          const parsedNotification = stringToJSONSchema
            .pipe(notificationSchema)
            .safeParse(data.subscribe.data)

          if (parsedNotification.success === false) {
            console.error('Invalid notification', parsedNotification.error)
            return
          }

          onNotificationReceived(parsedNotification.data)
        },
        error: (error) => console.error(error),
      })
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

export default NotificationsService
