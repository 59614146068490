import React from 'react'
import PropTypes from 'prop-types'
import { MessagesListItem } from './style'
import { CommonMessage } from './messages/CommonMessage'
import { RepliedMessage } from './messages/RepliedMessage'
import { StreamRoles } from '../../constants/streamRoles'
import { ModeratorMenu } from '../GJKit/Moderator/ModeratorMenu'
import { useModeratorMenu } from '../../hooks/useModeratorMenu'
import { CustomerRoles } from '../../constants/customerRoles'

export const ChatMessageV2 = ({ isDesktop, user, message }) => {
  const { showModeratorMenu, onMouseOver, onMouseLeave, handleShowModeratorMenu } =
    useModeratorMenu(user)

  const showMessage = message.type !== 'deleted' || message.user.id === user.id

  const renderMessage = (message) => {
    const isModerator =
      message.user.role == StreamRoles.ADMIN || message.user.role === CustomerRoles.MODERATOR

    if (message.type === 'reply') return <RepliedMessage message={message} isDesktop={isDesktop} />

    return <CommonMessage message={message} isDesktop={isDesktop} isModerator={isModerator} />
  }

  return (
    <>
      {showMessage ? (
        <MessagesListItem onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
          {renderMessage(message)}
          {showModeratorMenu && (
            <ModeratorMenu
              isDesktop={isDesktop}
              message={message}
              handleClick={handleShowModeratorMenu}
            />
          )}
        </MessagesListItem>
      ) : null}
    </>
  )
}

ChatMessageV2.propTypes = {
  isDesktop: PropTypes.bool,
  user: PropTypes.object.isRequired,
  message: PropTypes.object,
}
