import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const PopUpContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  align-self: left;
  border-radius: 0.5rem;
  padding: 1rem;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 75%;
  max-width: 75%;

  @media (min-height: 568px) and (max-height: 740px) {
    min-height: 4rem;
  }
`
export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #ffffff;
`

export const PopUpImage = styled(LazyLoadImage)`
  width: 100%;
  border-radius: 0.5rem;
  cursor: ${({ isurlactive }) => (isurlactive ? 'pointer' : 'default')};
  object-position: center;
  object-fit: fill;
  filter: brightness(93%);
  box-shadow: 1px 4px 3px 0px rgba(0, 0, 0, 0.5);
`

export const PopUpTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const PopUpTitle = styled.p`
  font-size: 0.7rem;
  font-weight: 500;
  text-align: left;
`

export const PopUpCuponContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const PopUpCuponText = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  align-self: center;
`
export const PopUpDescription = styled.span`
  font-size: 0.65rem;
  font-weight: 400;
  overflow-wrap: anywhere;
`
