import { utmParams } from '../constants/utmParams'

class UtmUtils {
  getUtmObject(queryParams) {
    return {
      utm_source: queryParams.utm_source,
      utm_medium: queryParams.utm_medium,
      utm_campaign: queryParams.utm_campaign,
      utm_term: queryParams.utm_term,
      utm_content: queryParams.utm_content,
    }
  }

  navigateWithUTM({ path, navigate, location }) {
    const searchParams = new URLSearchParams(location.search)
    let queryString = ''

    utmParams.forEach((param) => {
      const value = searchParams.get(param)
      if (value) {
        queryString += `${param}=${value}&`
      }
    })

    if (queryString) {
      queryString = '?' + queryString.slice(0, -1) // Remove trailing '&'
    }

    const finalPath = `/${path}${queryString}`
    navigate(finalPath)
  }
}

const instance = new UtmUtils()
Object.freeze(instance)

export default instance
