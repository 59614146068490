import store from '../../core/store'
import { setCheckoutFinishedSuccess } from '../../reducers/orderSlice'

const Kiehls = function (paymentGateway) {
  this.paymentGateway = paymentGateway
  this.quote = null
  this.checkout = null
  this.orderId = null
  this.products = []

  this.createQuote = ({ order }) => {
    this.quote = order
  }

  this.startCheckout = async () => null

  this.goToCheckout = ({ checkoutWindow }) => {
    const basketItems = this.quote.orderDetails.map((orderDetail, index) => ({
      pid: orderDetail.product.sku,
      qty: orderDetail.product.quantity,
      index: index,
    }))

    const checkoutUrl = new URL(
      '/on/demandware.store/Sites-kiehls-mx-Site/es_MX/Cart-AddAllProductsToCart',
      this.paymentGateway.urlCheckout,
    )

    const params = new URLSearchParams(checkoutUrl.search)

    basketItems.forEach((item) => {
      params.set(`pid_${item.index}`, item.pid)
      params.set(`qty_${item.index}`, item.qty)
    })
    params.set('utm_source', 'gojiraf')
    params.set('utm_medium', 'participant')
    params.set('utm_campaign', 'socios')

    checkoutUrl.search = params.toString()
    checkoutWindow.location.href = checkoutUrl.toString()
    checkoutWindow.focus()
    store.dispatch(setCheckoutFinishedSuccess(true))
  }
}

export default Kiehls
