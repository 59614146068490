import React from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import UtmUtils from '../../utils/utmUtils'
import { MainHeading } from './style'
import { useUtm } from '@gojiraf/useutm'

export const ChatHeader = ({ isModerator = false, usersCount }) => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { isAllowedToJoinCall: canWatchUsers } = useUtm(utm_medium)
  return (
    <MainHeading data-test="main-heading-text">
      Chat{isModerator || canWatchUsers ? ` (${usersCount})` : ''}
    </MainHeading>
  )
}

ChatHeader.propTypes = {
  isModerator: PropTypes.bool,
  usersCount: PropTypes.number.isRequired,
}
