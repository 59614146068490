import React from 'react'

export const CouponIconSVG = () => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.76 9.64287L8 7.99287L10.2 9.64287L9.36 6.97144L11.6 5.24287H8.88L8 2.57144L7.12 5.24287H4.4L6.6 6.97144L5.76 9.64287ZM1.6 12.7857C1.16 12.7857 0.783333 12.6319 0.47 12.3241C0.156667 12.0164 0 11.6464 0 11.2143V8.56251C0 8.41846 0.0466667 8.29406 0.14 8.18929C0.233333 8.08453 0.353333 8.01906 0.5 7.99287C0.82 7.8881 1.08333 7.69822 1.29 7.42322C1.49667 7.14822 1.6 6.84048 1.6 6.50001C1.6 6.15953 1.49667 5.85179 1.29 5.57679C1.08333 5.30179 0.82 5.11191 0.5 5.00715C0.353333 4.98096 0.233333 4.91548 0.14 4.81072C0.0466667 4.70596 0 4.58156 0 4.43751V1.78572C0 1.35358 0.156667 0.98364 0.47 0.675902C0.783333 0.368164 1.16 0.214294 1.6 0.214294H14.4C14.84 0.214294 15.2167 0.368164 15.53 0.675902C15.8433 0.98364 16 1.35358 16 1.78572V4.43751C16 4.58156 15.9533 4.70596 15.86 4.81072C15.7667 4.91548 15.6467 4.98096 15.5 5.00715C15.18 5.11191 14.9167 5.30179 14.71 5.57679C14.5033 5.85179 14.4 6.15953 14.4 6.50001C14.4 6.84048 14.5033 7.14822 14.71 7.42322C14.9167 7.69822 15.18 7.8881 15.5 7.99287C15.6467 8.01906 15.7667 8.08453 15.86 8.18929C15.9533 8.29406 16 8.41846 16 8.56251V11.2143C16 11.6464 15.8433 12.0164 15.53 12.3241C15.2167 12.6319 14.84 12.7857 14.4 12.7857H1.6ZM1.6 11.2143H14.4V9.21072C13.9067 8.92263 13.5167 8.53959 13.23 8.06162C12.9433 7.58364 12.8 7.0631 12.8 6.50001C12.8 5.93691 12.9433 5.41638 13.23 4.9384C13.5167 4.46043 13.9067 4.07739 14.4 3.78929V1.78572H1.6V3.78929C2.09333 4.07739 2.48333 4.46043 2.77 4.9384C3.05667 5.41638 3.2 5.93691 3.2 6.50001C3.2 7.0631 3.05667 7.58364 2.77 8.06162C2.48333 8.53959 2.09333 8.92263 1.6 9.21072V11.2143Z"
        fill="white"
      />
    </svg>
  )
}
