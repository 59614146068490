import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { useVirtualizer } from '@tanstack/react-virtual'
import { ChatMessageV2 } from './ChatMessage_v2'
import { ChatSpinnerContainer, VirtualizedMessageListContainer } from './style'
import { CircularProgress } from '@material-ui/core'
import { ScrollToBottom } from './buttons/ScrollToBottom'

export const VirtualizedMessageListV2 = ({
  messages,
  user,
  isDesktop,
  hasMoreMessages,
  fetchMoreMessages,
  isFetchingMoreMessages,
}) => {
  const [lastItemRef, inView] = useInView()
  const [autoScroll, setAutoScroll] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [paginationOffSet, setPaginationOffSet] = useState(0)
  const parentRef = useRef(null)
  const lastScrollTop = useRef(0)

  const rowVirtualizer = useVirtualizer({
    count: messages.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => (isDesktop ? 55 : 35),
    onChange: (virtualizer) => {
      const offsetForLastIndex = virtualizer.getOffsetForIndex(messages.length - 1)
      if (offsetForLastIndex !== undefined) {
        if (virtualizer.scrollOffset >= offsetForLastIndex[0] && autoScroll === false) {
          setAutoScroll(true)
          return
        }
      }
    },
  })

  const scrollToBottom = () => {
    rowVirtualizer.scrollToOffset(rowVirtualizer.getTotalSize(), {
      behavior: 'auto',
    })
  }

  useEffect(() => {
    if (autoScroll === false || messages.length === 0) {
      return
    }

    scrollToBottom()
  }, [messages.length])

  useEffect(() => {
    if (inView === true && hasMoreMessages === true && !isInitialLoad) {
      fetchMoreMessages()
    }
  }, [inView, hasMoreMessages])

  useEffect(() => {
    if (messages.length > 0 && isInitialLoad) {
      setTimeout(() => {
        scrollToBottom()
        setIsInitialLoad(false)
      }, 500)
    }
  }, [messages])

  useEffect(() => {
    const parentElement = parentRef.current

    const handleScroll = () => {
      const scrollTop = parentElement.scrollTop
      if (scrollTop < lastScrollTop.current) {
        setAutoScroll(false)
      }
      lastScrollTop.current = scrollTop
    }

    if (parentElement) {
      parentElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (isFetchingMoreMessages) {
      setPaginationOffSet(rowVirtualizer.getTotalSize())
    } else {
      if (isInitialLoad) return
      rowVirtualizer.scrollToOffset(rowVirtualizer.getTotalSize() - paginationOffSet, {
        behavior: 'auto',
      })
      setPaginationOffSet(rowVirtualizer.getTotalSize())
    }
  }, [isFetchingMoreMessages])

  return (
    <>
      {isFetchingMoreMessages && isDesktop ? (
        <ChatSpinnerContainer loading isDesktop>
          <CircularProgress size="1.5rem" color="secondary" />
        </ChatSpinnerContainer>
      ) : null}

      <VirtualizedMessageListContainer ref={parentRef} isDesktop={isDesktop}>
        <div
          className="relative"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={virtualItem.key}
              ref={rowVirtualizer.measureElement}
              data-index={virtualItem.index}
              className="absolute left-0 top-0 w-full"
              style={{
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {virtualItem.index === 0 ? <div ref={lastItemRef} data-for="last-item"></div> : null}
              {messages[virtualItem.index] !== undefined ? (
                <ChatMessageV2
                  isDesktop={isDesktop}
                  user={user}
                  message={messages[virtualItem.index]}
                />
              ) : null}
            </div>
          ))}
        </div>
      </VirtualizedMessageListContainer>
      <div
        className="absolute right-0"
        style={{
          bottom: isDesktop ? '80px' : 'auto',
          right: '0',
        }}
      >
        <ScrollToBottom onClick={scrollToBottom} isMessageListScrolledToBottom={autoScroll} />
      </div>
    </>
  )
}

VirtualizedMessageListV2.propTypes = {
  messages: PropTypes.array,
  parentRef: PropTypes.object,
  isDesktop: PropTypes.bool,
  hasMoreMessages: PropTypes.bool,
  fetchMoreMessages: PropTypes.func,
  isFetchingMoreMessages: PropTypes.bool,
  user: PropTypes.object,
}
