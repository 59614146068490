import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  checkoutProcessStatusStarted,
  createOrder,
  getOrderWithProducts,
  selectShippingType,
  setShippingType,
  setTotal,
  startCheckout,
} from '../reducers/orderSlice'
import UtmUtils from '../utils/utmUtils'
import { openWindow } from '../utils/browserDetectUtils'
import { useCart, usePDP, useProducts } from 'ui'
import { OwnCheckoutIntegrations } from '../constants/integrations'
import StoreUtils from '../utils/storeUtils'
import { useViews } from 'state'
import {
  CALL_CARD_CHECKOUT_FAILURE,
  setActiveCallCard,
  setGoToCheckoutDialog,
  setStockError,
} from '../reducers/uiSlice'
import { CheckoutStates } from '../constants/checkoutStates'
import PaymentGateways from '../strategy/payment/constants'
import { usePIP } from './usePIP'
import { useElasticEventTracker, useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { useEffect, useState } from 'react'
import { ShippingTypes } from '../constants/shippingTypes'

export const useCheckout = (store) => {
  const [redirection, setRedirection] = useState(false)
  const [clickBuyCheckout, setClickBuyCheckout] = useState(false)
  const [variantUrl, setVariantUrl] = useState(null)
  const [_orderData, _setOrderData] = useState(null)
  const [paymentGateway] = StoreUtils.getPaymentGateways(store)
  const dispatch = useDispatch()
  const shippingType = useSelector(selectShippingType)
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { getProductById, products: productsList } = useProducts()
  const { sendEventPostToElastic } = useElasticEventTracker()
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { setEnabled, isEnabled, canPIP } = usePIP()
  const hasOwnCheckout = OwnCheckoutIntegrations.includes(paymentGateway.type)
  const isToAgreeShippingType = paymentGateway.type === PaymentGateways.TO_AGREE
  const isLightIntegration = paymentGateway.type === PaymentGateways.LIGHT
  const { send: sendViews } = useViews()
  const { state: pdpState, send: sendPDP } = usePDP()
  const { send: sendCart, state: cartState } = useCart()

  const clearCart = () => {
    sendCart({ type: 'Reset Cart' })
  }

  const goToPDP = (productId) => {
    sendPDP({
      type: 'SET_PRODUCT',
      productId,
    })
    sendViews({
      type: 'SHOW_PDP',
    })
  }

  const goBack = () => {
    sendViews({ type: 'GO_BACK' })
  }

  const setCheckoutStatus = (checkoutStatus, checkoutWindow) => {
    if (checkoutStatus === CheckoutStates.INSUFFICIENT_STOCK) {
      checkoutWindow.close()
      sendViews({ type: 'SHOW_ORDER_STATUS' })
      dispatch(setStockError(true))
      dispatch(setActiveCallCard(CALL_CARD_CHECKOUT_FAILURE))
      return
    }
    if (checkoutStatus === CheckoutStates.NOT_INITIALIZED) {
      checkoutWindow.close()
      goBack()
      return
    }
    goBack()
  }

  const getQuantityDiff = (selectedVariant, selectedQuantity) => {
    const currentQuantityInCart = cartState.context.variants.get(selectedVariant.id) ?? 0
    const { limitPerOrder } = selectedVariant
    const exceedsLimit = currentQuantityInCart + selectedQuantity > limitPerOrder
    let quantityDiff = 0

    if (limitPerOrder === undefined) return { exceedsLimit, quantityDiff }
    if (exceedsLimit) {
      quantityDiff = currentQuantityInCart + selectedQuantity - limitPerOrder
    }

    return { quantityDiff, exceedsLimit }
  }

  const handleAddProductToCart = (selectedVariant, selectedQuantity) => {
    const { exceedsLimit, quantityDiff } = getQuantityDiff(selectedVariant, selectedQuantity)
    if (exceedsLimit === true && quantityDiff === 0) {
      goBack()
      return
    }

    if (cartState.context.variants.size === 0) {
      gaEventTracker('InCall > Products', 'cart-initialized')
      matomoTrackEvent('InCall > Products', 'cart-initialized')
    }
    gaEventTracker('InCall > Products', `add-product-to-cart [${selectedVariant?.name}]`)
    matomoTrackEvent('InCall > Products', `add-product-to-cart [${selectedVariant?.name}]`)

    sendCart({
      type: 'Add product variant',
      productVariantId: selectedVariant.id,
      quantity: selectedQuantity - quantityDiff,
    })
  }

  const getDefaultDeliveryMethod = () => {
    if (hasOwnCheckout) return ShippingTypes.UNAVAILABLE
    if (isToAgreeShippingType) return ShippingTypes.TO_AGREE
    return shippingType
  }

  const handleOnBuyClicked = async ({ total, selectedDeliveryMethod }) => {
    dispatch(setShippingType(selectedDeliveryMethod))
    dispatch(setTotal({ total: total }))
    if (hasOwnCheckout) await handleOwnCheckout()
    if (getDefaultDeliveryMethod() !== 'unavailable') sendViews({ type: 'GO_FORWARD' })
  }

  const handleQuickAddClick = async (featuredProduct) => {
    if (isLightIntegration) {
      handlePIPLightIntegration(featuredProduct, featuredProduct.skus[0].externalUrl)
      return
    }
    if (featuredProduct.skus?.length > 1) {
      goToPDP(featuredProduct.id)
      return
    }
    if (hasOwnCheckout) {
      handleAddProductToCart(featuredProduct.skus[0], 1)
      setClickBuyCheckout(true)
      return
    }
    goToPDP(featuredProduct.id)
  }

  const getItemsInCart = () => {
    const cartItems = []

    cartState.context.variants.forEach((variantQuantity, variantId) => {
      let variantFound

      const productFound = productsList?.find((findProduct) => {
        variantFound = findProduct.skus.find((variant) => variant.id === variantId)

        if (variantFound !== undefined) {
          return getProductById(pdpState.context.productId)
        }

        return undefined
      })

      if (productFound !== undefined && variantFound !== undefined) {
        cartItems.push({
          quantity: variantQuantity,
          product: productFound,
          ...variantFound,
        })
      }
    })

    return cartItems
  }

  const startCheckoutOwnWindowIntegrations = async ({ orderData, checkoutWindow }) => {
    try {
      await dispatch(createOrder(orderData)).then(unwrapResult)
      await dispatch(checkoutProcessStatusStarted()).then(unwrapResult)
      await dispatch(getOrderWithProducts()).then(unwrapResult)
      const startCheckoutStatus = await dispatch(
        startCheckout({ store, checkoutWindow, paymentGateway, productsList }),
      ).then(unwrapResult)
      setCheckoutStatus(startCheckoutStatus, checkoutWindow)
      clearCart()
    } catch (error) {
      console.error(error)
      goBack()
    }
  }

  const goToCheckout = async ({ orderData, checkoutWindow, selectedPaymentGateway, onGoBack }) => {
    const isPaymentGatewayPayPal = selectedPaymentGateway.type === PaymentGateways.PAYPAL
    try {
      await dispatch(createOrder(orderData)).then(unwrapResult)
      await dispatch(checkoutProcessStatusStarted()).then(unwrapResult)
      await dispatch(getOrderWithProducts()).then(unwrapResult)
      const startCheckoutStatus = await dispatch(
        startCheckout({
          productsList,
          store,
          checkoutWindow,
          paymentGateway: selectedPaymentGateway,
          orderData,
        }),
      ).then(unwrapResult)
      if (!isPaymentGatewayPayPal && startCheckoutStatus === CheckoutStates.STARTED) {
        clearCart()
      } else if (startCheckoutStatus !== CheckoutStates.STARTED) {
        checkoutWindow.close()
        return startCheckoutStatus
      }
    } catch (err) {
      console.error(err)
      onGoBack()
    }
  }

  const handleOwnCheckout = async () => {
    const details = []
    for (const [skuId, quantity] of cartState.context.variants.entries()) {
      details.push({
        skuId,
        quantity,
      })
    }

    const orderData = {
      paymentType: paymentGateway.type,
      utmParams: { ...UtmUtils.getUtmObject(queryParams) },
      details,
    }
    if (!isEnabled && canPIP) {
      matomoTrackEvent('Pip', 'open-autopip')
      _setOrderData(orderData)
      setRedirection(true)
      setEnabled(true)
      return
    } else {
      const checkoutWindow = openWindow()
      startCheckoutOwnWindowIntegrations({ orderData, checkoutWindow })
    }
  }

  const handlePIPLightIntegration = (product, selectedVariantURL) => {
    gaEventTracker('InCall > Products', `click-view-more-integration-light [${product.name}]`)
    matomoTrackEvent('InCall > Products', `click-view-more-integration-light [${product.name}]`)
    sendEventPostToElastic({
      event: 'InCall > Products',
      eventType: 'click-view-more-integration-light',
      productName: product.name,
    })
    const url = new URL(
      '?utm_source=gojiraf&utm_medium=participant&utm_campaign=socios',
      selectedVariantURL,
    )
    if (!isEnabled && canPIP) {
      matomoTrackEvent('Pip', 'open-autopip')
      setVariantUrl(url.toString())
      setRedirection(true)
      setEnabled(true)
    } else {
      const anchor = document.createElement('a')
      anchor.href = url.toString()
      anchor.target = '_blank'
      anchor.click()
      goBack()
    }
  }

  useEffect(() => {
    if (redirection) {
      setRedirection(false)
      if (isEnabled) {
        if (isLightIntegration || hasOwnCheckout) {
          dispatch(
            setGoToCheckoutDialog({
              paymentGateway,
              orderData: hasOwnCheckout ? _orderData : null,
              externalUrl: hasOwnCheckout ? null : variantUrl,
              showDialog: true,
            }),
          )
        }
      }
    }
  }, [isEnabled])

  return {
    hasOwnCheckout,
    isToAgreeShippingType,
    handlePIPLightIntegration,
    clearCart,
    goToCheckout,
    getItemsInCart,
    handleAddProductToCart,
    handleOnBuyClicked,
    handleQuickAddClick,
    clickBuyCheckout,
    setClickBuyCheckout,
    defaultDeliveryMethod: getDefaultDeliveryMethod(),
  }
}
