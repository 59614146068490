import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CoHostStreaming } from './CoHostStreaming'
import { useOtherBuyers } from '../../hooks/useOtherBuyers'
import { usePIP } from '../../hooks/usePIP'
import { useSelector } from 'react-redux'
import { selectLocalCameraEnabled } from '../../reducers/callSlice'
import { selectCurrentStore } from '../../reducers/storeSlice'

const StreamingContainer = styled.div`
  height: ${({ isCohostOnline, popUpCohostVideo }) =>
    isCohostOnline && !popUpCohostVideo ? '50%' : '100%'};
  width: 100%;
  filter: ${({ blurStreaming, popUpCohostVideo }) =>
    blurStreaming && popUpCohostVideo ? 'blur(4px)' : 'blur(0px)'};
  div {
    background-color: transparent !important;
  }
`
const VideosContainer = styled.div`
  height: 100%;
  filter: ${({ blurStreaming, popUpCohostVideo }) =>
    blurStreaming && !popUpCohostVideo ? 'blur(4px)' : 'none'};
`

const Streaming = ({ blurStreaming, isCohostUser = false, popUpCohostVideo }) => {
  const { cohostCameraEnabled, setVideoHandler, setAudioHandler, cohostUser } = useOtherBuyers()
  const [remoteCohostStreaming, setRemoteCohostStreaming] = useState(false)
  const { isEnabled } = usePIP()
  const { isOneToManySale } = useSelector(selectCurrentStore)
  const localVideoEnabled = useSelector(selectLocalCameraEnabled)
  const localCohostStreaming = isCohostUser && localVideoEnabled
  useEffect(() => {
    if (cohostUser && cohostUser?._video_added_ && isOneToManySale) setRemoteCohostStreaming(true)
    if (!cohostUser && remoteCohostStreaming) setRemoteCohostStreaming(false)
  }, [cohostUser])

  useEffect(() => {
    if (!isCohostUser && isOneToManySale) setRemoteCohostStreaming(cohostCameraEnabled)
  }, [cohostCameraEnabled])

  return (
    <VideosContainer blurStreaming={blurStreaming} popUpCohostVideo={popUpCohostVideo}>
      {(localCohostStreaming || remoteCohostStreaming) && (
        <CoHostStreaming
          data-test="video-cohost-container"
          activePIP={isEnabled}
          setVideoHandler={setVideoHandler}
          setAudioHandler={setAudioHandler}
          isCohostUser={isCohostUser}
          popUpCohostVideo={popUpCohostVideo}
          rtcUID={cohostUser?.uid}
        />
      )}

      <StreamingContainer
        id="video__streaming"
        data-test="react-player"
        blurStreaming={blurStreaming}
        isCohostOnline={localCohostStreaming || remoteCohostStreaming}
        popUpCohostVideo={popUpCohostVideo}
      />
    </VideosContainer>
  )
}

Streaming.propTypes = {
  children: PropTypes.element,
  blurStreaming: PropTypes.bool,
  isCohostUser: PropTypes.bool,
  popUpCohostVideo: PropTypes.bool,
}

export { Streaming }
