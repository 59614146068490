import { InternalProductsRepository } from '../../constants/productsRepositories'
import store from '../../core/store'
import { setCheckoutFinishedSuccess } from '../../reducers/orderSlice'
import { http } from '../../services/baseService'

const Magento2 = function (paymentGateway) {
  this.paymentGateway = paymentGateway
  this.quote = null
  this.checkout = null
  this.orderId = null

  this.createQuote = ({ order, productsList }) => {
    this.orderId = order.id
    this.quote = order.orderDetails.map((detail) => {
      const { COMPANY } = InternalProductsRepository
      const { productsRepository } = store.getState().store.current.company.companyConfigurations
      const baseProduct = productsList.find((product) =>
        product.skus.some((sku) => sku.sku == detail.product.sku),
      )
      const parentSku = productsRepository === COMPANY ? baseProduct.externalId : baseProduct.sku
      return {
        sku: detail.product.sku,
        qty: detail.product.quantity,
        parentSku: parentSku ?? detail.product.sku,
      }
    })
  }

  this.startCheckout = async () => {
    let response
    try {
      response = await http.post('/api/orders/startMagento2', {
        paymentGatewayId: this.paymentGateway.id,
        orderId: this.orderId,
        quote: this.quote,
      })
    } catch (err) {
      console.error(err)
      return
    }

    this.checkout = response.data
  }

  this.goToCheckout = ({ checkoutWindow }) => {
    checkoutWindow.location.href = new URL(
      `/gojiraf/cart/redirect?CART_ID=${this.checkout.checkout.quoteId}`,
      this.paymentGateway.urlCheckout,
    ).toString()
    checkoutWindow.focus()
    store.dispatch(setCheckoutFinishedSuccess(true))
  }
}

export default Magento2
