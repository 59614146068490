import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayPopUpState } from '../reducers/callSlice'
import { selectCurrentStore } from '../reducers/storeSlice'

export const usePopUp = () => {
  const [data, setData] = useState({ title: '', promoCode: '', description: '' })
  const dispatch = useDispatch()
  const {
    storeConfigurations: {
      features: {
        popUp: { active, data: storagedData },
      },
    },
  } = useSelector(selectCurrentStore)

  useEffect(() => {
    if (active) {
      setData(storagedData)
      dispatch(setDisplayPopUpState(true))
    }
  }, [])

  return { data, setData }
}
