import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDisplayStartEventCountdownstate,
  setDisplayStartEventCountdown,
} from '../reducers/callSlice'
import { selectCurrentStore, setStartEventCountdown } from '../reducers/storeSlice'
import { setDisableStartEvent, setEventStartServerDateTime } from '../reducers/uiSlice'
import { debounce } from 'lodash'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { CustomerRoles } from '../constants/customerRoles'
import { useAuth } from '@gojiraf/auth'
import { getDateTime } from '../utils/countdownTimerUtils'

export const useStartEventCountdown = () => {
  const {
    id: storeId,
    remainingSeconds,
    company: {
      companyConfigurations: {
        redirectToCall: { active: redirectToCallActive, secondsToRedirect },
      },
    },
  } = useSelector(selectCurrentStore)
  const {
    user: { role },
  } = useAuth()
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const displayStartEventCountdown = useSelector(selectDisplayStartEventCountdownstate)

  const dispatchStartCountdown = debounce(async () => {
    gaEventTracker('Moderator', 'click-switch-start-event-countdown')
    matomoTrackEvent('Moderator', 'click-switch-start-event-countdown')
    // dispatch(notifyStartEventCountdownMessage(!displayStartEventCountdown))  //Se comenta porque se elimina el método en CallService al no utilizar más el canal RTM de agora. No se eliminar el archivo porque requiere otro análisis.
    dispatch(setEventStartServerDateTime(getDateTime(secondsToRedirect)))
    dispatch(
      setStartEventCountdown({
        storeId,
        activateStartEventCountdown: displayStartEventCountdown,
        seconds: 600,
      }),
    )
  }, 1000)

  useEffect(() => {
    const isModerator = role === CustomerRoles.MODERATOR
    if (redirectToCallActive && remainingSeconds > 0) {
      if (isModerator || remainingSeconds <= secondsToRedirect) {
        dispatch(setDisplayStartEventCountdown(true))
        dispatch(setDisableStartEvent(true))
      }
    }
  }, [])

  return { displayStartEventCountdown, dispatchStartCountdown }
}
