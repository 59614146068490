import React from 'react'
import StoreUtils from '../../utils/storeUtils'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ProductsView } from 'ui'
import 'ui/styles.css'
import { useCheckout } from '../../hooks/useCheckout'
import { selectCurrentStore } from '../../reducers/storeSlice'

export function Products({ products, getProductsBySearchTerm }) {
  const store = useSelector(selectCurrentStore)
  const isLightIntegration = StoreUtils.getIsLightIntegration(store.paymentGateways)
  const {
    handleOnBuyClicked,
    handleAddProductToCart,
    getItemsInCart,
    hasOwnCheckout,
    defaultDeliveryMethod,
    handlePIPLightIntegration,
  } = useCheckout(store)

  return (
    <ProductsView
      products={products}
      hasOwnCheckoutIntegration={hasOwnCheckout}
      getProductsBySearchTerm={getProductsBySearchTerm}
      getItemsInCart={getItemsInCart}
      isLightIntegration={isLightIntegration}
      onBuyClicked={handleOnBuyClicked}
      handleAddProductToCart={handleAddProductToCart}
      handlePIPLightIntegration={handlePIPLightIntegration}
      defaultDeliveryMethod={defaultDeliveryMethod}
    />
  )
}

Products.propTypes = {
  products: PropTypes.array,
  getProductsBySearchTerm: PropTypes.func,
}
