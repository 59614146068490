import React from 'react'
import PropTypes from 'prop-types'

import { Camera } from './Camera'
import { Microphone } from './Microphone'
import { SwitchCamera } from './SwitchCamera'

const LocalMediaControls = ({
  toggleMicrophone,
  toggleCamera,
  layout = false,
  isCohostUser = false,
  isMobile = false,
}) => {
  return (
    <>
      <Camera layout={layout} toggleCamera={toggleCamera} />
      <Microphone layout={layout} toggleMicrophone={toggleMicrophone} />
      {isCohostUser && isMobile ? <SwitchCamera /> : null}
    </>
  )
}
LocalMediaControls.propTypes = {
  isCohostUser: PropTypes.bool,
  isMobile: PropTypes.bool,
  layout: PropTypes.bool,
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
}

export { LocalMediaControls }
