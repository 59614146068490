export const CallNotifications = {
  LIKE: 'like',
  FINISH_EVENT_COUNTDOWN: 'finish-event-countdown',
  START_EVENT_COUNTDOWN: 'start-event-countdown',
  SHOW_POPUP: 'show-popup',
  HIDE_POPUP: 'hide-popup',
  SHOW_REACTIONS: 'show-reactions',
  SHOW_QUICK_ADD: 'show-quick-add',
  HIDE_QUICK_ADD: 'hide-quick-add',
}
