"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  DevicesProvider: () => DevicesProvider,
  useDevices: () => useDevices
});
module.exports = __toCommonJS(src_exports);

// src/useDevices.tsx
var import_react = require("react");
var import_jsx_runtime = require("react/jsx-runtime");
var DevicesContext = (0, import_react.createContext)(void 0);
function DevicesProvider({ children }) {
  const [isDesktop, setIsDesktop] = (0, import_react.useState)(true);
  function checkDeviceType() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width > height) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }
  (0, import_react.useEffect)(() => {
    checkDeviceType();
    window.addEventListener("orientationchange", checkDeviceType);
    window.addEventListener("resize", checkDeviceType);
    return () => {
      window.removeEventListener("orientationchange", checkDeviceType);
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);
  const value = (0, import_react.useMemo)(() => ({ isDesktop }), [isDesktop]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(DevicesContext.Provider, { value, children });
}
var useDevices = () => {
  const context = (0, import_react.useContext)(DevicesContext);
  if (!context) {
    throw new Error("useDevices debe ser usado dentro de un DevicesProvider");
  }
  const { isDesktop } = context;
  return { isDesktop };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DevicesProvider,
  useDevices
});
