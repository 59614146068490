import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import qs from 'qs'
import StorageService from '../../../storage'
import {
  CALL_CARD_CASH_CHECKOUT_SUCCESS,
  CALL_CARD_CASH_CHECKOUT_FAILURE,
  setActiveCallCard,
  CALL_CARD_CHECKOUT_FAILURE,
  setStockError,
  CALL_CARD_CHECKOUT_SUCCESS,
  CALL_CARD_PRODUCTS,
  selectActiveCallCard,
  closeCallCard,
  setGoToCheckoutDialog,
} from '../../reducers/uiSlice'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { ShippingTypes } from '../../constants/shippingTypes'
import { StoreKeys } from '../../constants/storeKeys'
import { OwnFormCheckout, OwnWindowIntegrations } from '../../constants/integrations'
import UtmUtils from '../../utils/utmUtils'
import { getDireccionObject } from '../../utils/essenDireccionesUtils'
import { SecondaryHeading } from '../Kit/Headings/SecondaryHeading'
import { useTranslation } from 'react-i18next'
import { selectCountry } from '../../reducers/callSlice'
import {
  selectShippingType,
  selectCheckoutFinished,
  selectCheckoutSuccess,
  selectTotal,
} from '../../reducers/orderSlice'
import 'react-phone-number-input/style.css'
import { openWindow } from '../../utils/browserDetectUtils'
import PaymentGateways from '../../strategy/payment/constants'
import { CheckoutStates } from '../../constants/checkoutStates'
import { useLangCheckout } from '../../hooks/useLangCheckout'
import { useCheckout } from '../../hooks/useCheckout'
import { useViews } from 'state'
import { ViewContainer } from '../Call/DesktopCards/DesktopCard'
import { useAuth } from '@gojiraf/auth'
import { useLogger } from '@gojiraf/logger'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import StoreUtils from '../../utils/storeUtils'
import { getDynamicText, MainHeading, useCart, formatNumber, getCurrency, FormattedPrice } from 'ui'
import { CheckoutButtons } from '../Checkout/CheckoutButtons'
import { ENVIROMENTS } from '../../constants/enviroments'
import { TermsAndConditionsCheckbox } from '../Checkout/TermsAndConditionsCheckbox'
import {
  BoldText,
  BottomActions,
  Form,
  FormContainer,
  StyledSpinner,
  SummaryLine,
  SummaryTotal,
  Text,
} from './Checkout.styles'
import { RegularForm } from '../Checkout/Forms/RegularForm'
import { IntegrationForms } from '../Checkout/Forms/Integrations/IntegrationsForms'
import { useDevices } from '@gojiraf/responsive'
import { usePIP } from '../../hooks/usePIP'

export const Checkout = ({ onCallFinished, onGoBack = () => {} }) => {
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { isDesktop } = useDevices()
  const { t } = useTranslation()
  const store = useSelector(selectCurrentStore)
  const paymentGateways = StoreUtils.getPaymentGateways(store)
  const [paymentGateway, setPaymentGateway] = useState(paymentGateways[0])
  const dispatch = useDispatch()
  const [loadingPayment, setLoadingPayment] = useState(false)
  const activeCallCard = useSelector(selectActiveCallCard)
  const checkoutFinished = useSelector(selectCheckoutFinished)
  const checkoutSuccess = useSelector(selectCheckoutSuccess)
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { user } = useAuth()
  const shippingType = useSelector(selectShippingType)
  const total = useSelector(selectTotal)
  const countryCode = useSelector(selectCountry)
  const { setEnabled, isEnabled, canPIP } = usePIP()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm()
  const { storeConfigurations } = store
  const { active, price } = store.storeConfigurations.features.shipping
  const isDeliveryOrder = shippingType === ShippingTypes.DELIVERY
  const [checkoutInitializeStatus, setCheckoutInitializeStatus] = useState(null)
  const { langCheckout } = useLangCheckout(store)
  const { state } = useCart()
  const [productsQtyInCart] = useState(state.context.variants.size)
  const { send: sendViews } = useViews()
  const { clearCart, goToCheckout } = useCheckout(store)
  const { addLog } = useLogger()

  const goToProducts = () => {
    sendViews({ type: 'GO_BACK_TO_PRODUCTS' })
  }

  // esta función se ejecuta cuando el usuario se encuentra en el checkout y algún producto de su carrito fue eliminado del catalogo
  // tambien se ejecuta cuando el carrito se limpia despues de seleccionar un metodo de pago que no involucre un cambio especifico de la vista
  useEffect(() => {
    if (state.context.variants.size !== productsQtyInCart) goToProducts()
  }, [state.context.variants])

  const goToCheckoutFinished = () => {
    sendViews({ type: 'GO_FORWARD' })
  }

  useEffect(() => {
    if (process.env.REACT_APP_CHECKOUT_ENVIRONMENT === ENVIROMENTS.SANDBOX) {
      reset(StorageService.getValue(StoreKeys.ORDER_DATA))
    }
  }, [reset])

  useEffect(() => {
    if (activeCallCard == CALL_CARD_PRODUCTS) {
      goToProducts()
      dispatch(closeCallCard())
    }
  }, [activeCallCard])

  useEffect(() => {
    if (checkoutFinished) {
      goToCheckoutFinished()
      if (checkoutSuccess) {
        if (
          paymentGateway.type === PaymentGateways.CASH ||
          paymentGateway.type === PaymentGateways.TO_AGREE
        ) {
          dispatch(setActiveCallCard(CALL_CARD_CASH_CHECKOUT_SUCCESS))
        } else {
          dispatch(setActiveCallCard(CALL_CARD_CHECKOUT_SUCCESS))
        }
      } else {
        dispatch(setActiveCallCard(CALL_CARD_CASH_CHECKOUT_FAILURE))
      }
    }
  }, [checkoutFinished])

  useEffect(() => {
    if (checkoutInitializeStatus === CheckoutStates.NOT_INITIALIZED) {
      goToCheckoutFinished()
      dispatch(setStockError(false))
      dispatch(setActiveCallCard(CALL_CARD_CHECKOUT_FAILURE))
    } else if (checkoutInitializeStatus === CheckoutStates.INSUFFICIENT_STOCK) {
      goToCheckoutFinished()
      clearCart()
      dispatch(setStockError(true))
      dispatch(setActiveCallCard(CALL_CARD_CHECKOUT_FAILURE))
    }
  }, [checkoutInitializeStatus])

  const onSubmit = async (data, selectedPaymentGateway) => {
    gaEventTracker('InCall > Checkout', 'checkout-pay-button')
    matomoTrackEvent('InCall > Checkout', 'checkout-pay-button')
    setPaymentGateway(selectedPaymentGateway)
    setLoadingPayment(true)
    try {
      const details = []
      for (const [skuId, quantity] of state.context.variants.entries()) {
        details.push({
          skuId,
          quantity,
        })
      }

      let orderData = {
        ...data,
        paymentType: selectedPaymentGateway.type,
        utmParams: { ...UtmUtils.getUtmObject(queryParams) },
        details,
      }

      addLog({
        event: 'USER_CLICKED_PAY',
        data: {
          storeId: store.id,
          shippingType,
          userId: user.id,
          ...UtmUtils.getUtmObject(queryParams),
        },
      })

      if (['essen_ecommerce'].includes(selectedPaymentGateway.type)) {
        const directionObject = getDireccionObject(
          orderData.shipping.province.id,
          orderData.shipping.city.id,
        )
        orderData = { ...orderData, directionObject }
      }
      if (OwnWindowIntegrations.includes(selectedPaymentGateway.type) && !isEnabled && canPIP) {
        setEnabled(true)
        dispatch(
          setGoToCheckoutDialog({
            paymentGateway: selectedPaymentGateway,
            orderData,
            showDialog: true,
          }),
        )
        onGoBack()
      } else {
        let checkoutWindow
        if (OwnWindowIntegrations.includes(selectedPaymentGateway.type)) {
          checkoutWindow = openWindow()
        }
        const startCheckoutStatus = await goToCheckout({
          orderData,
          checkoutWindow,
          selectedPaymentGateway,
          onGoBack,
        })
        if (startCheckoutStatus) {
          setCheckoutInitializeStatus(startCheckoutStatus)
        } else {
          onGoBack()
        }
        setLoadingPayment(false)
      }
    } catch (err) {
      console.error(err)
      onGoBack()
      onCallFinished()
    }
  }

  const getShippingPrice = (total) => {
    return shippingType === ShippingTypes.TAKEAWAY ? total : total + price
  }

  const totalPrice = formatNumber({ price: total, countryCode })
  const priceProducts = formatNumber({ price: price, countryCode })
  const totalPriceShipping = formatNumber({ price: getShippingPrice(total), countryCode })

  const mainHeaderText = (paymentType) => {
    if (paymentType === PaymentGateways.TO_AGREE) return t('checkout.mainHeaderToAgree')
    if (paymentType === PaymentGateways.TIENDA_NUBE) return t('checkout.fillUserData')
    if (shippingType === ShippingTypes.TAKEAWAY) return t('checkout.mainHeaderTakeaway')

    return t('checkout.mainHeaderDelivery')
  }

  const secondaryHeaderText = (paymentType) => {
    if (paymentType === PaymentGateways.TO_AGREE)
      return (
        <>
          <span>{t('checkout.secondaryHeadingToAgreeOne')}</span>{' '}
          <BoldText>{t('checkout.secondaryHeadingToAgreeTwo')}</BoldText>
        </>
      )
    if (paymentType === PaymentGateways.TIENDA_NUBE)
      return (
        <>
          <span>{t('checkout.secondaryHeadingToAgreeOne')}</span>{' '}
        </>
      )
    return <BoldText>{t('checkout.secondaryHeadingTakeaway')}</BoldText>
  }

  return (
    <ViewContainer isCheckoutForm>
      <MainHeading
        className="px-8"
        title={mainHeaderText(paymentGateway.type)}
        onBackClicked={() => {
          onGoBack()
        }}
      />

      <FormContainer>
        <Form>
          <SecondaryHeading isDesktop={isDesktop} data-test="datos-de-facturacion">
            {secondaryHeaderText(paymentGateway.type)}
          </SecondaryHeading>
          {OwnFormCheckout.includes(paymentGateway.type) ? (
            <IntegrationForms
              integrationName={paymentGateway.type}
              control={control}
              langCheckout={langCheckout}
              countryCode={countryCode}
              storeLang={store.lang}
              isReseller={paymentGateway.isReseller}
            />
          ) : (
            <RegularForm
              isEssenStore={paymentGateway.type === PaymentGateways.ESSEN_ECOMMERCE}
              control={control}
              langCheckout={langCheckout}
              countryCode={countryCode}
              isDeliveryOrder={isDeliveryOrder}
              storeLang={store.lang}
            />
          )}

          <TermsAndConditionsCheckbox
            control={control}
            customTermsAndConditionsArray={store.customTermsAndConditions ?? []}
            termsAndConditionsError={errors.termsAndConditions}
          />
          <SecondaryHeading isDesktop={isDesktop} data-test="detalles-de-compra">
            {getDynamicText({
              paymentType: paymentGateway.type,
              textsOptions: {
                to_agree: t('checkout.secondaryHeadingDetailToAgree'),
                storeCustomizationText: '',
                defaultText: t('checkout.secondaryHeadingDetail'),
              },
            })}
          </SecondaryHeading>
          {paymentGateway.type !== PaymentGateways.TO_AGREE && (
            <SummaryLine data-test="monto-checkout">
              <span>{t('checkout.summaryLine')}</span>
              <FormattedPrice formattedNum={totalPrice} currency={getCurrency(countryCode)} />
            </SummaryLine>
          )}
          {isDeliveryOrder ? (
            <SummaryLine data-test="costo-de-envio-checkout">
              {active && price > 0 && (
                <>
                  <span>{t('checkout.shippingPrice')}</span>
                  <FormattedPrice
                    formattedNum={priceProducts}
                    currency={getCurrency(countryCode)}
                  />
                </>
              )}
            </SummaryLine>
          ) : null}
          <SummaryTotal data-test="total-checkout">
            <span>Total</span>
            {active && price > 0 ? (
              <FormattedPrice
                formattedNum={totalPriceShipping}
                currency={getCurrency(countryCode)}
              />
            ) : (
              <FormattedPrice formattedNum={totalPrice} currency={getCurrency(countryCode)} />
            )}
          </SummaryTotal>
          {shippingType === ShippingTypes.TAKEAWAY &&
          paymentGateway.type !== PaymentGateways.TO_AGREE ? (
            <Text data-test="takeawayText">{t('checkout.takeawayText')}</Text>
          ) : null}
          <BottomActions>
            {loadingPayment ? (
              <StyledSpinner />
            ) : (
              <CheckoutButtons
                storeConfigurations={storeConfigurations}
                paymentGateways={paymentGateways}
                handleSubmit={handleSubmit(onSubmit)}
              />
            )}
          </BottomActions>
        </Form>
      </FormContainer>
    </ViewContainer>
  )
}

Checkout.propTypes = {
  paymentGateways: PropTypes.func.isRequired,
  onCallFinished: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
}
