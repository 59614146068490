import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import remoteHeart from '../../../static/like-remote-animation.json'
import localHeart from '../../../static/like-local-animation.json'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectLocalLikeVisible,
  selectRemoteLikeVisible,
  setLocalLikeAnimation,
  setRemoteLikeAnimation,
} from '../../reducers/uiSlice'
import styled from 'styled-components'

export const ReactionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
`

const Reactions = () => {
  const showRemoteLike = useSelector(selectRemoteLikeVisible)
  const showLocalLike = useSelector(selectLocalLikeVisible)
  const dispatch = useDispatch()

  return (
    <>
      <ReactionsContainer data-test="local-reactions-container">
        {showRemoteLike === true ? (
          <Player
            autoplay={true}
            loop={false}
            src={remoteHeart}
            style={{ transform: 'scale(5) translateY(-50%)' }}
            onEvent={(event) => {
              if (event === 'complete') {
                dispatch(setRemoteLikeAnimation({ remoteLikeAnimation: false }))
              }
            }}
          />
        ) : null}
        {showLocalLike === true ? (
          <Player
            autoplay={true}
            loop={false}
            src={localHeart}
            style={{ transform: 'scale(3) translateY(5%)' }}
            onEvent={(event) => {
              if (event === 'complete') {
                dispatch(setLocalLikeAnimation({ localLikeAnimation: false }))
              }
            }}
          />
        ) : null}
      </ReactionsContainer>
    </>
  )
}

export { Reactions }
