import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import UtmUtils from '../../utils/utmUtils'
import { useUtm } from '@gojiraf/useutm'
import { Player } from '@lottiefiles/react-lottie-player'
import { ReactionsType } from '../../constants/reactionsType'
import remoteHeart from '../../../static/like-show-creator-animation.json'
import clapping from '../../../static/clapping-show-creator-animation.json'
import confetti from '../../../static/confetti-show-creator-animation.json'
import arrow from '../../../static/arrow-show-creator-animation.json'
import timer from '../../../static/timer-show-creator-animation.json'
import { useSelector, useDispatch } from 'react-redux'
import { selectExternalReaction, setExternalReaction } from '../../reducers/uiSlice'

const ExternalReactions = () => {
  const externalReaction = useSelector(selectExternalReaction)
  const [showReaction, setShowReaction] = useState(null)
  const dispatch = useDispatch()
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)

  const {
    DISPATCH_HEART_ANIMATION,
    DISPATCH_CONFETTI_ANIMATION,
    DISPATCH_CLAPPING_ANIMATION,
    DISPATCH_ARROW_ANIMATION,
    DISPATCH_TIMER_ANIMATION,
  } = ReactionsType

  const getAnimationData = (externalReaction) => {
    switch (externalReaction) {
      case DISPATCH_HEART_ANIMATION:
        return {
          animationData: remoteHeart,
          style: { transform: `scale(1.5) ${showUIComponents ? '' : 'translateY(20%)'}` },
        }
      case DISPATCH_CONFETTI_ANIMATION:
        return {
          animationData: confetti,
          style: {
            transform: `scale(3) ${showUIComponents ? 'translateY(-20%)' : 'translateY(20%)'}`,
          },
        }
      case DISPATCH_CLAPPING_ANIMATION:
        return {
          animationData: clapping,
          style: {
            transform: `scale(2) ${showUIComponents ? 'translateY(-20%)' : 'translateY(20%)'}`,
          },
        }
      case DISPATCH_TIMER_ANIMATION:
        return {
          animationData: timer,
          style: { transform: 'scale(1.5)' },
        }
      case DISPATCH_ARROW_ANIMATION:
        return {
          animationData: arrow,
          style: { transform: 'scale(0.5)' },
        }
      default:
        return {
          animationData: '',
          style: { transform: 'scale(1)' },
        }
    }
  }
  useEffect(() => {
    const { animationData } = getAnimationData(externalReaction)
    setShowReaction(animationData)
  }, [externalReaction])

  return (
    <ReactionsContainer data-test="external-reactions-container">
      {externalReaction !== null ? (
        <Player
          autoplay={true}
          loop={false}
          src={showReaction}
          style={getAnimationData(externalReaction).style}
          onEvent={(event) => {
            if (event === 'complete') {
              setShowReaction(null)
              dispatch(setExternalReaction({ externalReaction: null }))
            }
          }}
        />
      ) : null}
    </ReactionsContainer>
  )
}

const ReactionsContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  z-index: 1;
  pointer-events: none;
`
export { ExternalReactions }
