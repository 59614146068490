import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useChatV2 } from '@gojiraf/chat'
import { ChatDesktopContainer } from './style'
import { useViews } from 'state'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentStore } from '../../reducers/storeSlice'
import {
  CALL_ACTION_CAMERAS,
  disableChatInput,
  selectActiveCallAction,
  selectAutoJoinChat,
  setChatCooldown,
} from '../../reducers/uiSlice'
import { ChatModuleV2 } from './ChatModule_v2'
import { LaptopCameras } from '../GJKit/Cameras/LaptopCameras'
import { addNewMessageCount, selectIsOnCall, selectSeller } from '../../reducers/callSlice'
import { useAuth } from '@gojiraf/auth'
import CallService from '../../services/callService'
import AskNameDialog from '../dialogs/AskNameDialog'

export const ChatDesktopV2 = ({ toggleMicrophone, toggleCamera }) => {
  const [chatOpen, setChatOpen] = useState(true)
  const { user } = useAuth()
  const { id: sellerId } = useSelector(selectSeller)
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const isOnCall = useSelector(selectIsOnCall)
  const dispatch = useDispatch()
  const getChannelId = async () => await CallService.findEventId(sellerId)
  const { state, send } = useViews()
  const { isOneToManySale, storeConfigurations } = useSelector(selectCurrentStore)
  const {
    messages,
    initChat,
    chatConnected,
    hasMoreMessages,
    fetchMoreMessages,
    isFetchingMoreMessages,
    sendMessage,
    eventId,
    loadingChatConnection,
    setLoadingChatConnection,
  } = useChatV2({
    isOnCall,
    getChannelId,
    autoJoinChat,
    user,
    chatCooldownDefault: storeConfigurations.features.chat.cooldown,
    setChatCooldown: (action) => {
      dispatch(setChatCooldown(action))
    },
    addNewMessageCount: () => {
      if (state.matches('secondary.showingChat') === true) {
        return
      }
      dispatch(addNewMessageCount())
    },
    disableChatInput: (action) => {
      dispatch(disableChatInput(action))
    },
  })
  const activeCard = useSelector(selectActiveCallAction)

  useEffect(() => {
    if (activeCard === CALL_ACTION_CAMERAS) {
      send({ type: 'SHOW_PARTICIPANTS' })
    } else {
      send({ type: 'SHOW_CHAT' })
    }
  }, [])

  return (
    <ChatDesktopContainer>
      {!isOneToManySale && (
        <LaptopCameras
          style={{ display: state.matches('secondary.showingParticipants') === true ? '' : 'none' }}
          allowsMultipleBuyers={storeConfigurations.allowsMultipleBuyers}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
        />
      )}
      {state.matches('secondary.showingChat') === true && (
        <ChatModuleV2
          isDesktop
          loadingChatConnection={loadingChatConnection}
          setLoadingChatConnection={setLoadingChatConnection}
          isFetchingMoreMessages={isFetchingMoreMessages}
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          messages={messages}
          channelId={eventId}
          hasMoreMessages={hasMoreMessages}
          fetchMoreMessages={fetchMoreMessages}
          chatConnected={chatConnected}
          initChat={initChat}
          sendMessage={sendMessage}
          user={user}
        />
      )}
      <AskNameDialog eventId={eventId} />
    </ChatDesktopContainer>
  )
}

ChatDesktopV2.propTypes = {
  isFetchingMoreMessages: PropTypes.bool,
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
}
