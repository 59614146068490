import React from 'react'
import { ContainerUserBox, UserNameContainer, UserName } from './style'
import PropTypes from 'prop-types'
import { Video } from './Video'
import { Audio } from './Audio'
import { useDevices } from '@gojiraf/responsive'

const UserBox = ({ userName, audioTrack, videoTrack, dontPlay, usersCount, ...props }) => {
  const { isDesktop } = useDevices()

  return (
    <ContainerUserBox {...props}>
      <Audio isMobile={!isDesktop} track={audioTrack} dontPlay={dontPlay} />
      <Video isMobile={!isDesktop} track={videoTrack} userName={userName} usersCount={usersCount} />
      <UserNameContainer isMobile={!isDesktop}>
        <UserName isMobile={!isDesktop}>{userName}</UserName>
      </UserNameContainer>
    </ContainerUserBox>
  )
}

UserBox.propTypes = {
  userName: PropTypes.string,
  audioTrack: PropTypes.func,
  videoTrack: PropTypes.func,
  dontPlay: PropTypes.bool,
  usersCount: PropTypes.number,
}

export { UserBox }
