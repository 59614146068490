import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useViews } from 'state'
import { ChatBubble, ChatBubbleOutline } from '@material-ui/icons'
import { RoundedButton } from '../Kit/Buttons'
import { ButtonBubble } from './ButtonBubble'
import { selectMessagesCount, resetMessagesCount } from '../../reducers/callSlice'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'

export const ChatButton = () => {
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const { state, send } = useViews()
  const messagesCount = useSelector(selectMessagesCount)

  const openChat = () => {
    gaEventTracker('InCall', 'toggle-chat-button')
    matomoTrackEvent('InCall', 'toggle-chat-button')
    send({
      type: 'SHOW_CHAT',
    })
    dispatch(resetMessagesCount())
  }

  const ChatIcon = () =>
    state.matches('secondary.showingChat') === true ? <ChatBubble /> : <ChatBubbleOutline />

  return (
    <RoundedButton
      color="secondary"
      onClick={openChat}
      id="toggle-chat-button"
      data-test="toggle-chat-button"
    >
      {messagesCount && state.matches('secondary.showingChat') !== true ? (
        <ButtonBubble badgeContent={messagesCount} color="error">
          <ChatIcon />
        </ButtonBubble>
      ) : (
        <ChatIcon />
      )}
    </RoundedButton>
  )
}
