import React, { useMemo, useState } from 'react'
import StoreUtils from '../../utils/storeUtils'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useChatV2 } from '@gojiraf/chat'
import {
  disableChatInput,
  selectActiveCallCard,
  selectAutoJoinChat,
  setChatCooldown,
} from '../../reducers/uiSlice'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useViews } from 'state'
import { PDPContainer, Modal, useProducts, useCart, usePDP, Products as ProductsList } from 'ui'
import { AnimatePresence } from 'framer-motion'
import { Checkout } from '../GJKit/Checkout'
import { OrderStatus } from '../Checkout/OrderStatus'
import { addNewMessageCount, selectIsOnCall, selectSeller } from '../../reducers/callSlice'
import { useAuth } from '@gojiraf/auth'
import { JoinChatForm } from '../Chat/JoinChatForm'
import { Products } from '../Products/Products'
import { useCheckout } from '../../hooks/useCheckout'
import CallService from '../../services/callService'
import { ChatModuleV2 } from '../Chat/ChatModule_v2'

const CallActionV2 = ({ onCallFinished }) => {
  const [chatOpen, setChatOpen] = useState(true)
  const { user } = useAuth()
  const { id: sellerId } = useSelector(selectSeller)
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const isOnCall = useSelector(selectIsOnCall)
  const dispatch = useDispatch()
  const store = useSelector(selectCurrentStore)
  const getChannelId = async () => await CallService.findEventId(sellerId)
  const {
    initChat,
    messages,
    chatConnected,
    eventId,
    hasMoreMessages,
    fetchMoreMessages,
    isFetchingMoreMessages,
    sendMessage,
    loadingChatConnection,
    setLoadingChatConnection,
  } = useChatV2({
    isOnCall,
    getChannelId,
    autoJoinChat,
    user,
    chatCooldownDefault: store.storeConfigurations.features.chat.cooldown,
    setChatCooldown: (action) => {
      dispatch(setChatCooldown(action))
    },
    addNewMessageCount: () => {
      if (chatOpen === true) {
        return
      }
      dispatch(addNewMessageCount())
    },
    disableChatInput: (action) => {
      dispatch(disableChatInput(action))
    },
  })
  const activeCallCard = useSelector(selectActiveCallCard)
  const { type: paymentType } = store.paymentGateways.find(
    (paymentGatewayItem) => paymentGatewayItem.isDefault === true,
  )
  const isLightIntegration = StoreUtils.getIsLightIntegration(store.paymentGateways)
  const {
    handlePIPLightIntegration,
    getItemsInCart,
    hasOwnCheckout,
    handleAddProductToCart,
    handleOnBuyClicked,
  } = useCheckout(store)
  const { send: sendPDP } = usePDP()
  const { state, send: sendViews } = useViews()
  const { state: cartState } = useCart()
  const canBuy = useMemo(
    () => !!cartState.context.variants?.size,
    [cartState.context.variants?.size],
  )
  const { products, getProductsBySearchTerm } = useProducts({
    callId: sellerId,
  })

  const showProducts = () => {
    sendViews({
      type: 'SHOW_PRODUCTS',
    })
  }

  const onProductsListClose = () => {
    sendViews({
      type: 'SHOW_CHAT',
    })
  }

  const handleItemClicked = (productId) => {
    sendPDP({
      type: 'SET_PRODUCT',
      productId,
    })

    sendViews({
      type: 'SHOW_PDP',
    })
  }

  const goBack = () => {
    sendViews({
      type: 'GO_BACK',
    })
  }

  return (
    <>
      <ChatModuleV2
        loadingChatConnection={loadingChatConnection}
        setLoadingChatConnection={setLoadingChatConnection}
        chatOpen={chatOpen}
        setChatOpen={setChatOpen}
        messages={messages}
        hasMoreMessages={hasMoreMessages}
        fetchMoreMessages={fetchMoreMessages}
        isFetchingMoreMessages={isFetchingMoreMessages}
        chatConnected={chatConnected}
        channelId={eventId}
        initChat={initChat}
        sendMessage={sendMessage}
        user={user}
      />
      {state.matches('secondary.showingJoinChat') === true && (
        <Modal onClose={goBack}>
          <JoinChatForm eventId={eventId} />
        </Modal>
      )}
      <AnimatePresence>
        {state.matches('secondary.showingProducts') === true && (
          <Modal maxHeight="h-[80%]" size="LARGE" onClose={onProductsListClose}>
            <ProductsList
              getProductsBySearchTerm={getProductsBySearchTerm}
              paymentType={paymentType}
              isLightIntegration={isLightIntegration}
              canBuy={canBuy}
              products={products}
              onItemClicked={handleItemClicked}
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingCart') === true && (
          <Modal size="LARGE" onClose={goBack}>
            <Products products={products} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingPDP') === true && (
          <Modal size="LARGE" onClose={goBack}>
            <PDPContainer
              getItemsInCart={getItemsInCart}
              handlePIPLightIntegration={handlePIPLightIntegration}
              isLightIntegration={isLightIntegration}
              hasOwnCheckoutIntegration={hasOwnCheckout}
              handleAddProductToCart={handleAddProductToCart}
              handleBuyClicked={handleOnBuyClicked}
              goBack={showProducts}
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingOrder') === true && (
          <Modal onClose={showProducts}>
            <Checkout onCallFinished={onCallFinished} onGoBack={goBack} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingOrderStatus') === true && (
          <Modal onClose={goBack}>
            <OrderStatus activeCallCard={activeCallCard} />
          </Modal>
        )}
      </AnimatePresence>
      {state.matches('secondary.showingNone') === true && null}
    </>
  )
}

CallActionV2.propTypes = {
  onCallFinished: PropTypes.func.isRequired,
  setInitChat: PropTypes.func.isRequired,
}

export { CallActionV2 }
