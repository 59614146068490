"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __reExport = (target, mod, secondTarget) => (__copyProps(target, mod, "default"), secondTarget && __copyProps(secondTarget, mod, "default"));
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  sendEventToElastic: () => sendEventToElastic,
  useElasticEventTracker: () => useElasticEventTracker,
  useGoogleAnalytics: () => useGoogleAnalytics,
  useMatomoAnalytics: () => useMatomoAnalytics
});
module.exports = __toCommonJS(src_exports);

// src/hooks/useAnalyticsEventTracker.ts
var import_react_ga4 = __toESM(require("react-ga4"));
var useGoogleAnalytics = () => {
  const path = window.location.pathname + window.location.search;
  const gaEventTracker = (category, event) => {
    import_react_ga4.default.event({ category, action: event, label: event });
  };
  const gaSendPageView = () => {
    gaEventTracker("Page View", `page-view-${path}`);
    import_react_ga4.default.send({ hitType: "pageview", page: path });
  };
  return {
    gaEventTracker,
    gaSendPageView
  };
};

// src/hooks/useElasticEventTracker.ts
var import_state = require("state");
var import_auth = require("@gojiraf/auth");

// src/utils/connectionInfo.ts
function getConnectionInfo() {
  if ("connection" in navigator && navigator.connection instanceof NetworkInformation) {
    return {
      downlink: navigator.connection.downlink,
      effectiveType: navigator.connection.effectiveType,
      rtt: navigator.connection.rtt,
      type: navigator.connection.type
    };
  }
  return null;
}

// src/hooks/useElasticEventTracker.ts
var getURLParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    utmSource: searchParams.get("utm_source"),
    utmMedium: searchParams.get("utm_medium"),
    utmCampaing: searchParams.get("utm_campaign")
  };
};
var getDeviceType = () => window.innerWidth >= 768 ? "desktop" : "mobile";
var downlink;
var effectiveType;
var rtt;
var type;
var conecctionInfo = getConnectionInfo();
if (conecctionInfo !== null) {
  downlink = conecctionInfo.downlink;
  effectiveType = conecctionInfo.effectiveType;
  rtt = conecctionInfo.rtt;
  type = conecctionInfo.type;
}
var ElasticMap = {
  0: [],
  1: [
    "like-local-animation",
    "like-message-sent",
    "call-share-button",
    "entered-live-event",
    "click-view-more-integration-light",
    "cart-buy-button-to-checkout",
    "quick-buy"
  ],
  2: [
    "click-img-product-detail",
    "featured-product",
    "click-product-detail-card",
    "click-product-detail-button",
    "confirm-name-chat",
    "user-initialized-chat",
    "add-product-to-cart",
    "cart-initialized"
  ],
  3: []
};
var sendToElastic = (store, user, elasticLogData) => {
  var _a, _b;
  const urlParams = getURLParams();
  const postData = {
    storeId: store.id,
    companyId: store.companyId,
    buyerId: user.id,
    productName: (_a = elasticLogData.productName) != null ? _a : void 0,
    utmParams: {
      utmSource: urlParams.utmSource || "",
      utmMedium: urlParams.utmMedium || "",
      utmCampaing: urlParams.utmCampaing || ""
    },
    device: getDeviceType(),
    eventType: elasticLogData.eventType,
    event: elasticLogData.event
  };
  if (elasticLogData.eventType === "errors") {
    postData.connectionInfo = {
      downlink: `${downlink} Mbps`,
      effectiveType: `${effectiveType}`,
      rtt: `${rtt} ping`,
      type: `${type}`
    };
  }
  const urlApi = process.env.REACT_APP_CORE_URL;
  const userToken = (_b = user.tokens) == null ? void 0 : _b.accessToken;
  fetch(`${urlApi}/api/userLogs/elasticLog`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`
    },
    body: JSON.stringify(postData)
  });
};
var useElasticEventTracker = () => {
  const store = (0, import_state.useStore)((storeState) => storeState.store);
  const { user } = (0, import_auth.useAuth)();
  const sendEventPostToElastic = (elasticLogData) => {
    const elasticLogKey = store.storeConfigurations.elasticLogs;
    if (elasticLogKey === 3) {
      sendToElastic(store, user, elasticLogData);
      return;
    }
    let eventsToTrack = [];
    for (let i = 0; i <= elasticLogKey; i++) {
      eventsToTrack = [...eventsToTrack, ...ElasticMap[i]];
    }
    const shouldTrack = eventsToTrack.some((e) => elasticLogData.eventType.startsWith(e));
    if (shouldTrack)
      sendToElastic(store, user, elasticLogData);
  };
  return { sendEventPostToElastic };
};

// src/hooks/useMatomoAnalytics.ts
var import_matomo_tracker_react = require("@datapunt/matomo-tracker-react");
var import_state2 = require("state");
var MatomoMap = {
  0: [],
  1: ["add-product-to-cart", "add-to-calendar-button", "buy-from-pdp", "call-share-button", "cart-buy-button-to-checkout", "cart-initialized", "click-img-product-detail", "click-product-detail-button", "click-product-detail-card", "click-view-more-integration-light", "confirm-name-chat", "entered-live-event", "featured-product", "product-catalog-buy-button-to-checkout", "quick-buy", "user-initialized-chat"],
  2: [
    "like-local-animation",
    "like-message-sent"
  ],
  3: []
};
var useMatomoAnalytics = () => {
  const { trackEvent, trackPageView, enableLinkTracking } = (0, import_matomo_tracker_react.useMatomo)();
  const store = (0, import_state2.useStore)((storeState) => storeState.store);
  const matomoTrackEvent = (category, event) => {
    const matomoLogKey = store.storeConfigurations.matomoLogs;
    if (matomoLogKey === 3) {
      trackEvent({ category, action: event });
      return;
    }
    let eventsToTrack = [];
    for (let i = 0; i <= matomoLogKey; i++) {
      eventsToTrack = [...eventsToTrack, ...MatomoMap[i]];
    }
    const shouldTrack = eventsToTrack.some((e) => event.startsWith(e));
    if (shouldTrack) {
      trackEvent({ category, action: event });
    }
  };
  return {
    MatomoProvider: import_matomo_tracker_react.MatomoProvider,
    createInstance: import_matomo_tracker_react.createInstance,
    matomoTrackEvent,
    enableLinkTracking,
    trackPageView
  };
};

// src/index.ts
__reExport(src_exports, require("@datapunt/matomo-tracker-react"), module.exports);

// src/utils/sendEventToElastic.ts
var getURLParams2 = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    utmSource: searchParams.get("utm_source"),
    utmMedium: searchParams.get("utm_medium"),
    utmCampaing: searchParams.get("utm_campaign")
  };
};
var getDeviceType2 = () => window.innerWidth >= 768 ? "desktop" : "mobile";
var downlink2;
var effectiveType2;
var rtt2;
var type2;
var conecctionInfo2 = getConnectionInfo();
if (conecctionInfo2 !== null) {
  downlink2 = conecctionInfo2.downlink;
  effectiveType2 = conecctionInfo2.effectiveType;
  rtt2 = conecctionInfo2.rtt;
  type2 = conecctionInfo2.type;
}
var sendEventToElastic = (store, user, eventType, event, productName) => {
  var _a;
  try {
    const postData = {
      storeId: store == null ? void 0 : store.id,
      companyId: store == null ? void 0 : store.companyId,
      buyerId: user == null ? void 0 : user.id,
      productName: productName != null ? productName : void 0,
      utmParams: {
        utmSource: getURLParams2().utmSource || "",
        utmMedium: getURLParams2().utmMedium || "",
        utmCampaing: getURLParams2().utmCampaing || ""
      },
      device: getDeviceType2(),
      eventType: eventType || event,
      event
    };
    if (eventType === "errors") {
      postData.connectionInfo = {
        downlink: `${downlink2} Mbps`,
        effectiveType: `${effectiveType2}`,
        rtt: `${rtt2} ping`,
        type: `${type2}`
      };
    }
    const urlApi = process.env.REACT_APP_CORE_URL;
    const userToken = (_a = user.tokens) == null ? void 0 : _a.accessToken;
    fetch(`${urlApi}/api/userLogs/elasticLog`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      },
      body: JSON.stringify(postData)
    });
  } catch (error) {
    console.log(error);
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  sendEventToElastic,
  useElasticEventTracker,
  useGoogleAnalytics,
  useMatomoAnalytics
});
