import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { IconButton } from '@material-ui/core'

const CustomIconButton = styled(IconButton)`
  ${({ filled }) =>
    filled
      ? css`
          background-color: white;

          &:hover {
            background-color: white;
          }
        `
      : ''}
  padding: ${({ layout }) => (layout ? '8px' : '3px')};
`

const RoundedButton = ({ filled, ...props }) => {
  return <CustomIconButton size="small" color="error" filled={filled} {...props} />
}

RoundedButton.propTypes = {
  filled: PropTypes.bool,
}

export { RoundedButton }
