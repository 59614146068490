/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publish = /* GraphQL */ `
  mutation Publish($name: String!, $data: AWSJSON!) {
    publish(name: $name, data: $data) {
      name
      data
      __typename
    }
  }
`;
