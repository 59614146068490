import React from 'react'
import PropTypes from 'prop-types'

export const SellerNotAvailableSVG = ({ width = 'auto', height = '55%' }) => {
  return (
    <svg width={width} height={height} viewBox="25 25 300 300" fill="none">
      <path
        d="M280.607 158.592c-5.44 17.692-18.303 48.886-65.826 69.258-1.491.638-5.602-1.75-7.162-2.33-1.306-.486-2.29-1.54-3.2-2.565a135.852 135.852 0 01-7.443-9.168c-.773-1.041-1.566-2.225-1.402-3.445.274-2.037-4.06-30.635-2.029-29.891 30.455 11.145 41.572 3.05 73.273-26.061 4.563-4.191 14.395 2.231 13.789 4.202z"
        fill="#EDEDED"
      />
      <path
        d="M278.61 160.846c-5.439 17.696-18.304 48.888-65.828 69.258-1.491.637-5.6-1.75-7.16-2.331-1.309-.483-2.289-1.54-3.199-2.562a136.416 136.416 0 01-7.441-9.17c-.777-1.043-1.568-2.227-1.407-3.444.273-2.037-4.06-30.632-2.03-29.89.476.175.952.343 1.421.511.791 8.253 2.268 18.921 2.107 20.139-.168 1.218.63 2.401 1.4 3.444a136.115 136.115 0 007.448 9.17c.903 1.022 1.89 2.08 3.199 2.562 1.554.581 5.67 2.968 7.161 2.331 41.447-17.766 56.532-43.771 63.315-61.866.756.727 1.154 1.399 1.014 1.848z"
        fill="#DCD8D8"
      />
      <path
        d="M291.289 159.832c.449.593.963 1.227 1.714 1.333.826.117 1.649-.428 2.334-.987 7.223-5.896 18.834-11.048 29.794-16.102-33.4-46.086-34.762-47.709-34.762-47.709s-23.459 12.37-30.969 21.019c-.403.465-.814.944-1.036 1.511-.835 2.126 22.974 27.794 32.925 40.935z"
        fill="#575757"
      />
      <path
        d="M290.387 156.018c.418.607.896 1.26 1.613 1.435.787.193 1.586-.242 2.251-.705 7.023-4.882 18.227-8.621 28.806-12.334-31.036-47.073-32.307-48.749-32.307-48.749s-22.675 9.419-30.027 16.886c-.394.401-.796.816-1.02 1.332-.84 1.938 21.426 28.67 30.684 42.135z"
        fill="#8A8888"
      />
      <path
        d="M285.212 151.729c.269.687.587 1.432 1.243 1.766.723.366 1.599.125 2.352-.174 7.952-3.153 19.712-4.24 30.86-5.443-19.487-52.908-20.344-54.83-20.344-54.83s-24.224 4.002-33.085 9.596c-.476.301-.961.613-1.297 1.065-1.258 1.695 14.326 32.799 20.271 48.02z"
        fill="#fff"
      />
      <path
        d="M276.216 142.025c2.208-3.783 3.201-6.671 5.41-10.455.4-.685.867-1.429 1.751-1.48.747-.042 1.411.46 1.735 1.136.504 1.053.234 2.233-.094 3.316a32.745 32.745 0 01-2.975 6.879c3.567 4.392 2.668 11.247-.843 15.684-.336.423-.705.84-1.19 1.076-1.466.71-7.553-2.904-7.983-3.154-.734-.427 1.584-6.976-.567-17.472-.092-.448.609-.639.921-.304 1.452 1.563 2.999 3.119 3.835 4.774z"
        fill="#FFC1C0"
      />
      <path
        d="M273.724 107.795a202.99 202.99 0 0124.131-6.629c.44-.093.254-.767-.187-.675a203.195 203.195 0 00-24.131 6.629c-.422.145-.241.821.187.675zM275.069 111.223l20.861-5.096c.437-.107.252-.782-.186-.675l-20.861 5.096c-.438.106-.252.782.186.675zM277.14 116.759a176.938 176.938 0 0124.72-7.257c.44-.095.253-.77-.186-.674a176.647 176.647 0 00-24.719 7.256c-.42.156-.238.833.185.675zM280.335 122.245l14.913-5.175c.423-.147.241-.823-.187-.675l-14.912 5.175c-.423.147-.241.823.186.675zM281.678 126.689a17.028 17.028 0 005.458-2.195c.381-.237.031-.843-.354-.604a16.445 16.445 0 01-5.29 2.124c-.441.094-.254.768.186.675zM139.32 218.017c-12.832 2.991-26.191 5.801-36.142 14.959-17.209 15.835-4.525 35.526-9.963 32.671-3.622-1.901-8.977 10.411-12.084 7.86-9.375-7.699-17.712-42.208 12.804-70.279 11.453-10.535 23.969-15.323 38.785-17.38"
        fill="#EDEDED"
      />
      <path
        d="M99.296 267.623a47.912 47.912 0 015.651 3.581c.427.315.877.679.997 1.196.123.53-.154 1.094-.577 1.434-.424.34-.97.49-1.508.566-1.632.23-3.282-.148-4.887-.524-.448-.104-.913-.218-1.266-.513-.427-.358-.615-.918-.785-1.449l-.766-2.408c-.103-.327-.208-.665-.17-1.006.09-.781.931-1.298 1.717-1.305.786-.007 1.507.382 1.594.428z"
        fill="#FFC1C0"
      />
      <path
        d="M98.877 284.498l-14.104-41.036a.647.647 0 01.401-.822l3.575-1.229a.647.647 0 01.821.401l14.115 41.065a.636.636 0 01.034.245l-.239 4.421a.647.647 0 01-1.094.431l-3.346-3.221a.643.643 0 01-.163-.255z"
        fill="#575757"
      />
      <path
        d="M90.287 257.038c-1.059-.399-2.2-.243-3.28.1-3.39 1.075-7.673 3.897-8.555 7.569-.596 2.484-.02 5.075 1.27 7.246.633 1.067 1.425 2.036 2.301 2.912.788.787 1.889 1.182 2.856 1.733.792.452 1.972.804 2.466.038a15.955 15.955 0 003.566 2.032c1.44.59 3.191.933 4.476.055.7.394 1.416.796 2.208.927.791.132 1.686-.059 2.196-.679.88.767 2.12 1.575 3.114.962.789-.487.885-1.592.78-2.514-.383-3.326-2.207-6.279-3.551-9.347-1.159-2.647-1.92-5.495-4.22-7.41-.846-.703-1.784-1.282-2.731-1.834-.839-.488-1.59-1.114-2.443-1.582a3.38 3.38 0 00-.453-.208z"
        fill="#FFC1C0"
      />
      <path d="M112.773 289.252H66.748v5.25h46.025v-5.25z" fill="#F9F9F9" />
      <path
        d="M141.611 180.089c-17.607 8.426-25.396 13.465-40.098 52.12-3.458 9.091-1.83 20.493 4.813 27.597 4.704 5.031 11.843 7.2 18.729 7.319 1.908.034 3.865-.084 5.623-.827 4.761-2.012 6.561-7.752 7.392-12.853 1.124-6.902 1.504-13.901 1.883-20.884.965-17.768 1.928-35.536 1.658-52.472zM190.805 178.787c19.99 12.649 39.817 33.861 44.823 55.795 2.164 9.484 1.83 20.492-4.814 27.597-4.704 5.031-11.842 7.201-18.728 7.32-1.909.033-3.865-.085-5.623-.828-4.762-2.011-6.562-7.751-7.392-12.853-1.125-6.902-1.505-13.901-1.883-20.884-.964-17.767-6.653-39.21-6.383-56.147z"
        fill="#fff"
      />
      <path d="M176.668 160.848h-18.97v31.325h18.97v-31.325z" fill="#FFC1C0" />
      <path
        d="M142.535 140.005c-.783-1.459-2.194-2.61-3.824-2.904-1.629-.294-3.437.353-4.373 1.72-.785 1.146-.904 2.615-.861 4.003.046 1.532.268 3.087.94 4.465.671 1.377 1.841 2.565 3.313 2.991 1.349.39 2.804.116 4.133-.335"
        fill="#FAC895"
      />
      <path
        d="M168.188 152.171c-1.598-2.437-4.34-4.012-6.019-6.4-2.114-3.007-2.252-7.031-.957-10.437 1.074-2.827 3.03-5.284 4.127-8.103 1.476-3.794 4.834-5.281 4.069-9.055-.673-3.324-5.366-7.251-8.739-9.112-4.173-2.301-9.321-2.458-14.037-1.53-4.896.965-9.615 3.084-13.151 6.467-3.536 3.382-5.8 8.087-5.66 12.871.095 3.237 1.239 6.558.208 9.642-.803 2.398-2.961 4.336-5.511 4.948-2.835.68-5.808-.206-8.729-.124-2.896.08-5.75 1.153-7.93 2.98-1.932 1.617-3.345 3.867-3.634 6.308-.289 2.442.641 5.05 2.623 6.61 1.356 1.067 3.25 1.774 3.771 3.379.583 1.795-1.008 3.633-2.804 4.393-1.795.761-3.828.855-5.657 1.539-4.091 1.531-6.51 6.012-6.118 10.201.392 4.19 3.227 7.945 6.901 10.25 3.673 2.306 8.102 3.298 12.485 3.573 4.383.274 8.776-.128 13.16-.394 5.014-.304 10.13-.446 14.863-2.061 4.785-1.633 8.893-4.68 12.717-7.883 1.81-1.517 3.645-3.174 4.432-5.351.572-1.581-.662-8.326 0-9.874 1.421-3.322 6.697.212 9.018-2.607 2.353-2.858 2.593-7.148.572-10.23z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M202.956 125.42c-2.915-.04-5.755 1.351-8.673 1.405-3.675.068-7.087-2.069-9.185-5.049-1.741-2.472-2.681-5.469-4.404-7.956-2.317-3.346-1.67-6.962-5.226-8.44-3.132-1.303-9.012.387-12.442 2.141-4.243 2.17-7.255 6.348-9.125 10.776-1.942 4.596-2.826 9.693-2.003 14.517s3.455 9.332 7.498 11.895c2.736 1.733 6.128 2.644 8.106 5.225 1.538 2.007 1.935 4.88 1.015 7.336-1.023 2.73 9.838 2.765 8.271 5.231-1.554 2.445-.293 7.671 0 10.5.26 2.506-13.897 4.609-12.036 6.215 1.862 1.606 4.543 2.295 6.945 1.526 1.643-.526 3.29-1.7 4.911-1.233 1.814.522 2.446 2.868 2.071 4.782-.374 1.913-1.434 3.651-1.891 5.549-1.022 4.247 1.337 8.76 5.029 10.781 3.691 2.02 8.389 1.772 12.356.019 3.967-1.754 7.268-4.868 9.949-8.347 2.68-3.478 4.807-7.344 7.04-11.125 2.555-4.325 5.301-8.644 6.612-13.47 1.325-4.88 1.1-9.989.586-14.951-.244-2.349-.59-4.797-1.953-6.668-.99-1.359-2.451-2.314-3.363-3.729-1.958-3.036-.894-7.229-1.931-10.73-1.052-3.549-4.472-6.149-8.157-6.2z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity={0.3098}
        d="M189.384 135.589l-21.467-18.712-1.079.301 19.319 17.079c3.559 33.684-27.291 29.165-27.291 29.165-4.195-.419-7.648-1.375-10.492-2.701 3.488 3.051 8.516 5.353 15.724 6.072 0 0 28.845 2.48 25.286-31.204z"
        fill="#FAC895"
      />
      <path
        d="M188.037 139.689c.899-1.391 2.399-2.423 4.047-2.584 1.648-.16 3.397.632 4.219 2.07.69 1.205.689 2.68.534 4.06-.172 1.522-.519 3.054-1.3 4.373-.78 1.319-2.043 2.407-3.545 2.713-1.376.279-2.804-.112-4.092-.67"
        fill="#FAC895"
      />
      <path
        d="M167.183 193.897c12.511 0 22.652-4.975 22.652-11.113 0-6.137-10.141-11.112-22.652-11.112-12.51 0-22.652 4.975-22.652 11.112 0 6.138 10.142 11.113 22.652 11.113z"
        fill="#FFC1C0"
      />
      <path
        d="M127.633 275.857v-62.425c0-17.748 11.167-35.038 27.266-40.045 2.106-1.114 8.854 11.149 12.061 14.145 0 0 8.796-11.653 12.154-14.145 16.22 4.934 27.619 22.211 27.619 40.045l-3.85 64.149c0 3.231 1.231 4.801-2 4.801h-66.724a6.526 6.526 0 01-6.526-6.525z"
        fill="#EDEDED"
      />
      <path
        d="M187.163 149.853c.255-1.11.407-2.341.04-3.445-.345-1.038-1.283-1.852-1.476-2.936-.108-.604.125-1.196.35-1.744.234-.57.463-1.13.59-1.736a8.084 8.084 0 00-.002-3.315 7.75 7.75 0 00-1.355-3.067c-.707-.947-1.603-1.716-2.533-2.438-1.971-1.531-4.562-3.193-4.922-5.896-.064-.479-.825-.486-.761 0 .668 5.068 7.039 6.054 8.629 10.598a7.17 7.17 0 01.321 3.453 7.407 7.407 0 01-.505 1.743c-.235.557-.503 1.108-.59 1.713-.165 1.15.481 2.034 1.048 2.965.672 1.103.767 2.215.506 3.465-.289 1.378-.788 2.705-.956 4.108-.187 1.574.1 3.09.554 4.593.123.409.7.235.629-.173-.241-1.368-.543-2.787-.432-4.183.103-1.266.583-2.474.865-3.705zM188.712 135.284a13.716 13.716 0 00-.863-3.489c-.838-2.15-2.134-4.059-3.587-5.838-.251-.307-.68.13-.434.435 1.379 1.712 2.625 3.586 3.41 5.65.405 1.066.692 2.186.802 3.322.113 1.158-.012 2.257-.148 3.405-.042.343.479.377.577.078.368-1.117.37-2.406.243-3.563zM190.781 130.163c-.117-2.467-.67-4.889-1.736-7.121-.191-.399-.78-.051-.594.346 1.019 2.167 1.487 4.545 1.59 6.927.051 1.173.02 2.35-.08 3.52-.105 1.222-.338 2.418-.508 3.63-.054.383.491.564.599.165.327-1.205.483-2.477.61-3.718.129-1.245.179-2.498.119-3.749z"
        fill="#C56752"
      />
      <path
        d="M193.546 141.884c-.735.081-1.408.503-1.893 1.067.097-1.099.436-2.174 1.069-3.083.25-.36-.333-.69-.583-.341-.703.974-1.012 2.132-1.108 3.318-.107 1.312.01 2.733.318 4.014.068.283.53.228.513-.07-.05-.915-.192-1.815-.229-2.726a.283.283 0 00.143-.124c.049-.097.103-.19.163-.28.03-.048.064-.094.097-.139.034-.048-.008.008.015-.019l.048-.06c.14-.166.297-.315.466-.45.006-.005.069-.054.039-.032-.029.022.035-.026.042-.029.042-.03.084-.06.127-.088a3.26 3.26 0 01.281-.163c.217-.111.339-.153.567-.237.131-.049.224-.165.203-.311-.019-.127-.137-.262-.278-.247z"
        fill="#D9914E"
      />
      <path
        opacity={0.4314}
        d="M184.598 111.322s10.278 14.128 2.642 23.043c0 0-3.362 2.091-.501 7.136 2.86 5.045 1.516 7.788-.031 9.994-1.547 2.207-.536 6.753 1.361 8.943 0 0 .722-.29-.269-3.856 0 0-.941-2.314.811-3.907 0 0 3.447-3.364.312-10.921 0 0-.084-5.627 5.527-3.936 0 0 3.857-8.973-3.99-20.726-.129-.193-5.709-5.625-5.88-5.784l.018.014z"
        fill="#C56752"
      />
      <path
        d="M152.469 173.889c1.256-.576 3.367-1.234 2.69.015-1.245 2.295-2.958 6.753-2.583 9.472.38 2.756 1.895 5.195 3.594 7.337a37.694 37.694 0 007.54 7.205c.825.592 1.716 1.199 2.135 2.15.294.666.319 1.423.339 2.157.685 25.62 1.018 51.25.999 76.879-.001 1.442-.106 3.108-1.218 3.96-.755.579-1.762.598-2.698.594l-30.423-.139c-1.299-.006-2.719-.057-3.703-.943-.917-.826-1.358-2.139-1.448-3.401-1.622-22.774-2.165-40.06-2.165-70.038 0-5.516 1.613-11.033 4.163-15.86 1.482-2.805 3.475-5.283 5.627-7.562 4.876-5.166 10.81-8.923 17.151-11.826zM182.682 173.889c-1.179-.576-3.836-1.234-3.201.015 1.167 2.295 3.453 6.753 3.101 9.472-.356 2.756-1.778 5.195-3.371 7.337a36.476 36.476 0 01-7.073 7.205c-.774.592-1.609 1.199-2.002 2.15-.276.666-.299 1.423-.318 2.157-.642 25.62-.955 51.25-.937 76.879.001 1.442.1 3.108 1.142 3.96.708.579 1.653.598 2.531.594l28.536-.139c1.219-.006 2.55-.057 3.474-.943.86-.826 1.273-2.139 1.358-3.401 1.521-22.774 2.03-40.06 2.03-70.038 0-5.516-1.512-11.033-3.904-15.86-1.39-2.805-3.26-5.283-5.279-7.562-4.573-5.166-10.14-8.923-16.087-11.826z"
        fill="#fff"
      />
      <path
        d="M141.375 135.409s-6.285 28.487 22.723 31.384c0 0 28.845 2.48 25.285-31.204l-21.467-18.712-20.022 5.587-6.519 12.945z"
        fill="#FFC1C0"
      />
      <path
        d="M134.019 122.889s11.448 5.19 4.896 14.193c0 0 10.281 2.024 12.356-9.71 2.075-11.734 15.435-6.004 30.819 12.093 5.914 6.956 18.487-10.109 8.933-25.894-9.555-15.785-31.495-29.432-57.004 9.318z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M193.919 129.965a681.396 681.396 0 01-11.08 20.659c-.299.536-.612 1.108-.73 1.786-.167.966.103 1.86.366 2.645.916 2.738 3.264 8.954 4.803 11.937.781 1.513 2.578 1.033 3 .237 2.826-5.334 9.592-20.773 9.665-22.521.035-.845-.511-4.07-.738-4.755-1.11-3.347-1.834-7.224-3.252-10.177-.368-.762-1.608-.638-2.034.189z"
        fill="#888"
      />
      <path
        d="M195.342 129.966a683.623 683.623 0 01-11.079 20.659c-.3.536-.613 1.108-.731 1.786-.167.965.103 1.86.366 2.645.916 2.738 3.487 8.848 4.803 11.937.345.81 1.353.823 1.775.026 2.826-5.334 10.817-20.562 10.889-22.311.035-.844-.511-4.069-.738-4.754-1.109-3.347-2.534-6.314-3.952-9.267-.367-.763-.908-1.549-1.333-.721z"
        fill="#575757"
      />
      <path
        d="M194.905 201.602c0-12.6 13.919-20.251 8.595-35.271-.569-1.605-1.262-3.128-.065-4.339 2.455-2.48 3.886-6.322 5.747-9.423.977-1.626 3.22-1.936 4.629-.667 30.848 27.795 42.103 69.491-11.711 82.15"
        fill="#fff"
      />
      <path
        d="M225.64 212.935c12.947-17.237 3.171-41.689-16.082-60.861 1.092-1.183 3.003-1.302 4.256-.175 23.578 21.25 35.709 50.624 16.076 68.936"
        fill="#DFDDDD"
      />
      <path
        d="M202.904 162.339c-2.772-1.399-3.381-3.707-4.928-6.399-6.576-11.444-6.32-16.939-5.693-18.284 1.692-3.627 6.755-3.261 8.223-2.693 3.122 1.208 5.761 3.499 7.685 6.239 1.924 2.74 3.167 5.913 3.962 9.166.344 1.407.607 2.851.514 4.296a8.933 8.933 0 01-3.404 6.405c-1.973 1.523-4.611 2.151-6.359 1.27z"
        fill="#FEB3B1"
      />
      <path
        opacity={0.2642}
        d="M212.668 154.666c-.161 2.485-1.435 4.879-3.402 6.405-1.974 1.519-4.613 2.149-6.363 1.267-.077-.035-.147-.077-.217-.119a8.506 8.506 0 002.016-1.148c1.974-1.526 3.241-3.92 3.402-6.405.098-1.449-.168-2.891-.511-4.298-.798-3.255-2.037-6.426-3.962-9.17-1.925-2.737-4.564-5.026-7.686-6.237a4.86 4.86 0 00-.175-.063c1.89-.518 3.899-.259 4.732.063 3.129 1.211 5.761 3.5 7.686 6.237 1.925 2.744 3.171 5.915 3.962 9.17.343 1.407.609 2.849.518 4.298z"
        fill="#FFC1C0"
      />
      <path
        d="M214.48 156.1a11.806 11.806 0 01-2.447 5.189 12.29 12.29 0 01-2.057 1.995c-.778.589-1.629 1.029-2.475 1.505-.289.164-.096.627.226.537 1.919-.542 3.668-2.005 4.916-3.518a12.383 12.383 0 002.617-5.492c.1-.509-.68-.727-.78-.216z"
        fill="#E1E1E1"
      />
      <path
        d="M205.995 165.286a.27.27 0 00-.332-.188c-.091.024-.182.047-.272.076l-.269.088a.296.296 0 00-.168.128.283.283 0 00.318.414l.269-.087c.09-.03.178-.064.266-.098a.29.29 0 00.161-.124.277.277 0 00.027-.209z"
        fill="#F1F3F4"
      />
      <path
        d="M115.134 291.31l-4.969-57.466c-.171-1.981 1.803-3.661 4.299-3.661h107.002c2.518 0 4.5 1.708 4.296 3.703l-5.873 57.467c-.182 1.779-2.051 3.149-4.296 3.149h-96.16c-2.266-.001-4.144-1.395-4.299-3.192z"
        fill="#575757"
      />
      <path
        d="M65.027 295.202a.7.7 0 01.7-.7h218.4a.7.7 0 110 1.4h-218.4a.7.7 0 01-.7-.7z"
        fill="#8A8888"
      />
      <path
        d="M130.056 219.628l-5.026 10.357 6.092.153-1.066-10.51zM199.386 219.628l5.026 10.357-6.093.153 1.067-10.51z"
        fill="#CCC"
      />
      <path d="M64.14 204.915c-.271 0-.272.423 0 .423.272-.001.272-.423 0-.423z" fill="#FFE4C1" />
      <path
        d="M66.2 110.457c1.494 2.615 2.497 5.481 3.898 8.147 1.402 2.666 3.305 5.213 6.003 6.55 2.699 1.337 6.317 1.149 8.352-1.071 1.112-1.212 1.632-2.849 1.989-4.456a30.372 30.372 0 00-.275-14.223c-1.084-4.128-3.567-8.447-7.729-9.394-3.913-.892-7.985 1.688-9.968 5.177-1.983 3.488-2.29 7.669-2.27 9.27z"
        fill="#FFC1C0"
      />
      <path
        d="M74.326 123.228c.23 18.481 6.656 37.191 20.106 50.666 8.79 8.806 20.111 15.04 31.266 21.152 1.421.779 2.961 1.584 4.621 1.467 1.39-.098 2.632-.831 3.793-1.558a135.955 135.955 0 009.727-6.697c1.035-.781 2.13-1.692 2.317-2.91.311-2.032 8.989-11.799 6.841-12.047-20.875-2.415-30.134-7.711-57.694-40.271-4.003-4.729-5.69-12.383-12.24-13.327-3.896-.561-8.763 1.463-8.737 3.525z"
        fill="#fff"
      />
      <path
        d="M122.156 193.101c-11.158-6.111-18.935-10.402-27.727-19.208-13.447-13.475-19.873-32.186-20.104-50.666-.007-.609.406-1.211 1.092-1.736a1.146 1.146 0 00-.07.504c1.26 18.438 8.715 36.764 22.89 49.469 9.268 8.302 20.079 12.46 30.767 14.965"
        fill="#DFDDDD"
      />
      <path
        d="M39.658 104.948c4.993 7.24 10.225 16.48 16.516 22.575.724.7 1.828.833 2.688.31 13.349-8.099 26.685-18.874 38.74-28.818a2.191 2.191 0 00.572-2.678c-3.23-6.34-12.689-17.407-16.508-21.778-.725-.83-1.945-1-2.868-.395-5.378 3.522-14.385 9.86-18.79 12.839-7.297 4.936-12.67 9.603-19.843 14.919a2.207 2.207 0 00-.507 3.026z"
        fill="#575757"
      />
      <path
        d="M57.794 87.727a1.036 1.036 0 00-.177 1.255 97.202 97.202 0 004.601 7.231c.679.969 1.621 2.038 2.792 1.869.48-.07.903-.347 1.307-.618.69-.46 1.38-.922 2.069-1.384.167-.111.344-.235.414-.424.097-.259-.04-.54-.175-.782a60.253 60.253 0 00-6.54-9.452c-.149-.176-.322-.364-.552-.388-.194-.02-.377.084-.543.187a18.814 18.814 0 00-3.196 2.506z"
        fill="#EDEDED"
      />
      <path d="M64.417 95.636a1.707 1.707 0 100-3.413 1.707 1.707 0 000 3.413z" fill="#000" />
      <path
        d="M81.131 112.538c.151-3.213.762-6.646 2.194-9.555.498-1.014 1.17-1.925 2.078-2.607.545-.41 1.854-1.404 2.576-.922.481.322 1.1.84.972 1.404-1.314 5.81-.802 10.582-1.797 13.945-.67 2.264-.277.879-6.023-2.265z"
        fill="#FFC1C0"
      />
      <path d="M93.83 99.394c.017-.013.041-.038 0 0z" fill="#000" />
      <path
        d="M76.035 124.612a20.714 20.714 0 018.807-2.043c.45-.003.452-.703 0-.7-3.175.027-6.3.761-9.16 2.138-.407.196-.052.799.353.605zM229.969 219.475c-2.29-5.308-5.712-10.145-9.318-14.642-3.648-4.548-7.673-8.779-11.826-12.87-2.345-2.309-4.731-4.576-7.112-6.848-.386-.367-.971.214-.586.583 4.2 4.022 8.385 8.07 12.356 12.319 3.952 4.229 7.696 8.663 10.925 13.47 1.805 2.688 3.412 5.498 4.965 8.336.219.398.766.047.596-.348zM139.138 180.611c-7.454 3.109-13.929 8.209-19.186 14.298-5.674 6.572-9.884 14.255-13.181 22.254a134.234 134.234 0 00-5.025 14.51c-.096.337.417.475.524.144 2.738-8.545 5.716-17.011 10.12-24.863 3.994-7.121 9.094-13.689 15.46-18.846 3.473-2.814 7.325-5.181 11.451-6.907.358-.149.201-.742-.163-.59z"
        fill="#E1E1E1"
      />
      <path
        d="M121.264 190.769a121.63 121.63 0 00-2.574-.736c-.374-.103-.534.478-.161.58.862.234 1.726.457 2.593.673.335.083.473-.418.142-.517z"
        fill="#FFDFC5"
      />
      <path
        d="M162.667 69.76c-.453.057-.861.347-1.11.73-.251.381-.354.847-.35 1.304.009.936.496 1.873 1.297 2.358.803.484 1.901.464 2.644-.107.265-.204.482-.47.645-.762.529-.952.433-2.236-.29-3.052-.722-.815-2.043-1.053-2.836-.47zM168.151 54.702c.205-.664.411-1.334.743-1.944.331-.61.8-1.167 1.418-1.487.209-.108.439-.189.675-.178.235.01.475.125.588.332.123.226.075.503.02.756-.28 1.279-.66 2.536-1.134 3.756"
        fill="#FFE36C"
      />
      <path
        d="M172.218 55.875c-1.654-1.269-3.75-1.878-5.815-1.596-.979.134-1.952.407-2.794.926-3.22 1.987-3.45 6.653-5.952 9.492-.283.322-.618.63-1.036.723-.953.21-2.121-.722-2.816-.038-.378.372-.296 1.035.034 1.45.33.415.836.64 1.323.85 5.482 2.364 11.045 4.604 16.843 6.025.686.168 1.418.324 2.089.103.671-.22 1.222-.964.978-1.627-.223-.604-.933-.837-1.544-1.036-.85-.277-.553-.285-1.336-.715-.289-.158 5.685-10.22.026-14.557z"
        fill="#FFE36C"
      />
      <path
        d="M158.371 66.42a93.92 93.92 0 0111.842 4.01c.414.17.733-.454.315-.626a95.49 95.49 0 00-12.019-4.07c-.436-.115-.573.572-.138.687zM162.344 70.92c.991.212 1.953.527 2.877.942.173.078.382.02.47-.155.082-.163.018-.392-.155-.47a15.214 15.214 0 00-3.055-1.004c-.439-.094-.579.592-.137.687z"
        fill="#EFCF4B"
      />
      <path
        d="M167.261 55.102a6.948 6.948 0 00-5.488 4.762c-.134.43.553.565.686.138.67-2.153 2.62-3.843 4.846-4.202.439-.07.401-.77-.044-.698z"
        fill="#FFF4C5"
      />
      <path
        d="M27.797 145.43c-.24 3.304-.497 6.607-.74 9.911-.056.752-.18 1.496.322 2.121.545.68 1.363.609 2.13.654 1.875.109 3.743.314 5.596.62.93.154 1.851.34 2.774.533.72.15 1.465-.112 1.846-.805.212-.385.237-.85.301-1.28a126.85 126.85 0 00.588-4.401 131.026 131.026 0 00.444-4.465c.037-.456.116-.938.098-1.396a1.746 1.746 0 00-1.327-1.637 29 29 0 00-2.436-.511 54.076 54.076 0 00-4.978-.589 106.45 106.45 0 00-2.26-.147c-.684-.038-1.406-.164-1.94.4-.258.273-.378.616-.418.992-.025.236.211.441.42.441.247 0 .393-.204.418-.441.07-.668.777-.551 1.225-.526.67.036 1.338.076 2.006.122a57.79 57.79 0 014.431.459c.78.116 1.558.252 2.33.42.368.079.743.153 1.104.263.24.073.442.255.534.503.126.337.026.76-.002 1.108a130.024 130.024 0 01-1.084 9.439c-.052.337-.06.717-.302.973a.812.812 0 01-.69.247c-.398-.048-.798-.171-1.192-.248a56.129 56.129 0 00-7.69-.966c-.32-.017-.707.033-1.006-.107-.587-.275-.468-.93-.428-1.479.226-3.063.463-6.125.69-9.188l.074-1.019c.04-.567-.797-.564-.838-.001z"
        fill="#8A8888"
      />
      <path
        d="M33.396 155.002c.044.071.098.148.178.16.075.012.144-.037.205-.083 2.47-1.887 7.404-6.368 10.073-8.667a.437.437 0 00-.022-.676 3 3 0 01-.932-1.116c-.045-.091-.112-.202-.208-.187a.233.233 0 00-.097.051c-1.406 1.071-6.753 5.811-8.085 7.145a.395.395 0 01-.594-.034c-.56-.7-1.086-1.43-1.576-2.184a.393.393 0 00-.542-.129l-.386.242c-.201.126-.409.257-.547.455-.35.503 1.698 3.7 2.533 5.023z"
        fill="#000"
      />
      <path
        d="M283.318 220.844c-.49-.133-.777-.672-.644-1.219.155-.641 3.921-15.688 13.726-14.732 2.085.203 3.718 1.209 4.852 2.989 3.48 5.461.941 16.491.831 16.958-.132.559-.649.918-1.157.803-.511-.114-.814-.662-.683-1.22.025-.106 2.437-10.595-.571-15.311-.832-1.303-1.987-2.01-3.534-2.161-8.159-.796-11.595 12.969-11.629 13.108-.135.557-.656.912-1.162.793l-.029-.008z"
        fill="#FF6B41"
      />
      <path
        d="M256.777 253.88l18.067-40.041 25.138 6.362-4.225 47.274-37.568-10.24c-1.336-.365-2.004-1.952-1.412-3.355z"
        fill="#F93700"
      />
      <path
        d="M304.715 221.981l-4.734-1.78-4.224 47.274 4.823 1.315c.977.266 1.978-.525 2.066-1.634l2.069-45.175z"
        fill="#FFDBDA"
      />
      <path
        d="M277.973 219.258c-.489-.133-.776-.672-.644-1.219.156-.641 3.921-15.688 13.727-14.732 2.085.203 3.718 1.209 4.852 2.989 3.48 5.461.941 16.492.831 16.959-.132.558-.65.917-1.158.802-.509-.108-.813-.661-.682-1.22.025-.105 2.437-10.594-.571-15.311-.832-1.303-1.987-2.01-3.534-2.161-8.16-.796-11.595 12.969-11.629 13.108-.135.558-.656.912-1.163.793l-.029-.008z"
        fill="#F93700"
      />
      <path
        d="M131.284 73.506l-12.777 2.123c-.512.085-1.037-.185-1.307-.671l-4.744-8.541a1.468 1.468 0 01-.074-1.274c.172-.407.52-.69.931-.758l19.645-3.265-.946 11.547c-.036.429-.335.774-.728.84zm-17.891-8.64a.788.788 0 00-.599.488.933.933 0 00.047.82l4.744 8.541c.174.314.512.487.842.433l12.777-2.123a.446.446 0 00.358-.413l.895-10.913-19.064 3.168z"
        fill="#000"
      />
      <path
        d="M132.929 61.463l-.445-.1 1.134-6.667 2.461-.688.124.472-2.191.613-1.083 6.37z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M133.095 87.192l-10.63 1.767c-.33.054-.657-.112-.834-.424a.925.925 0 01.036-.982l9.414-14.436a.213.213 0 01.314-.053c.103.08.129.232.058.34l-9.418 14.443a.404.404 0 00-.021.442c.081.142.22.213.371.188l10.63-1.767c.122-.02.24.071.262.205.022.133-.059.257-.182.277zM132.014 69.086l-16.398 2.725c-.123.02-.24-.07-.262-.204-.022-.133.059-.257.182-.278l16.398-2.724c.123-.02.24.07.262.203.022.133-.059.258-.182.278zM132.347 65.927l-18.311 3.042c-.122.02-.24-.07-.262-.204-.022-.133.06-.257.182-.278l18.311-3.042c.122-.02.24.07.262.204.022.133-.06.257-.182.278z"
        fill="#000"
      />
      <path
        d="M120.113 75.37a.216.216 0 01-.034.003c-.22-.001-.224-.001-2.225-5.63-.019-.055-1.939-5.46-1.948-5.557l.225-.018.195.129-.195-.13.215-.069c.027.088 3.534 10.088 3.893 10.862a.26.26 0 01.062.185.236.236 0 01-.188.225zM122.783 74.917c-.123.02-.24-.07-.263-.204l-1.877-11.3c-.022-.133.059-.258.182-.278.123-.02.24.07.262.204l1.878 11.3c.022.134-.06.258-.182.278zM126.389 74.321a.154.154 0 01-.014.002c-.124.012-.236-.087-.25-.221l-1.181-11.153a.236.236 0 01.198-.264c.124-.012.236.088.25.222l1.181 11.152c.014.129-.067.243-.184.262zM129.098 73.87a.216.216 0 01-.051.003c-.124-.01-.221-.126-.215-.26l.49-11.695c.006-.134.112-.235.236-.226.125.01.221.127.215.261l-.49 11.694a.23.23 0 01-.185.224zM123.151 91.454c.72-.12 1.198-.849 1.068-1.629-.129-.78-.818-1.315-1.537-1.196-.72.12-1.198.85-1.069 1.63.13.78.818 1.315 1.538 1.195z"
        fill="#000"
      />
      <path
        d="M123.043 90.813c.392-.066.653-.463.582-.889-.07-.426-.446-.718-.838-.653-.393.066-.654.464-.583.89.071.425.446.717.839.652z"
        fill="#fff"
      />
      <path
        d="M132.63 89.881c.72-.12 1.198-.849 1.069-1.629-.13-.78-.818-1.316-1.538-1.196-.72.12-1.198.849-1.068 1.63.129.78.818 1.315 1.537 1.195z"
        fill="#000"
      />
      <path
        d="M132.524 89.238c.392-.065.653-.463.582-.888-.07-.426-.446-.718-.838-.653-.393.066-.654.463-.583.889.071.425.446.718.839.652z"
        fill="#fff"
      />
      <path
        d="M223.624 107.975c12.495 0 22.624-9.397 22.624-20.988 0-11.59-10.129-20.987-22.624-20.987S201 75.396 201 86.987c0 11.591 10.129 20.988 22.624 20.988z"
        fill="#000"
      />
      <path
        d="M223.624 104.556c10.459 0 18.938-7.866 18.938-17.569 0-9.702-8.479-17.568-18.938-17.568-10.46 0-18.939 7.866-18.939 17.569 0 9.702 8.479 17.568 18.939 17.568z"
        fill="#fff"
      />
      <path
        d="M223.258 71.496c.016-.193-.176-.36-.36-.36-.206 0-.345.166-.36.36-.071.98-.011 2.003.262 2.949.079.274.545.236.518-.07-.086-.962-.138-1.913-.06-2.879zM240.286 87.42c-.587-.127-1.19-.189-1.786-.267-.18-.024-.352.175-.344.344.01.204.152.32.344.344.567.068 1.136.15 1.706.17.335.011.414-.517.08-.59zM209.282 88.06c-.062-.191-.224-.258-.41-.234l-.075.01-.138.015c-.09.012-.179.032-.268.047a6.5 6.5 0 01-.403.056 7.668 7.668 0 01-.949.037c-.187-.004-.365.168-.357.357a.364.364 0 00.357.357c.329.009.658-.003.986-.037.349-.037.692-.085 1.024-.2.165-.056.291-.23.233-.409zM224.395 100.436a.398.398 0 00-.39.39c-.017.374-.039.747-.044 1.12-.006.374-.025.75.021 1.12.019.154.104.287.271.307.147.018.309-.069.343-.223.084-.387.102-.787.131-1.182.029-.379.041-.761.058-1.142a.392.392 0 00-.39-.39z"
        fill="#575757"
      />
      <path
        d="M232.634 88.62c-1.47-.086-2.961-.009-4.434-.029-1.452-.02-2.902-.07-4.355-.108-.08-.002-.375.028-.498 0 .03-.043-.003-.225-.001-.285.012-.368.026-.737.04-1.105.027-.737.043-1.474.052-2.211.021-1.475.035-2.949.055-4.422.006-.397-.608-.397-.616 0-.036 1.579-.083 3.158-.112 4.738-.015.79-.021 1.58-.02 2.37 0 .475-.21 1.348.427 1.477.339.069.723.04 1.069.055.395.017.791.027 1.187.036.792.019 1.583.03 2.376.037 1.597.015 3.236.097 4.83-.04.326-.03.331-.494 0-.513z"
        fill="#000"
      />
      <path
        d="M209.22 80.396c.58.392 1.161.784 1.74 1.177.138.093.358.03.434-.115a.325.325 0 00-.114-.434l-1.741-1.176c-.138-.093-.357-.031-.434.114a.326.326 0 00.115.434zM213.583 74.96l1.182 1.603c.098.133.279.214.434.114.132-.085.219-.291.114-.434l-1.181-1.602c-.099-.133-.28-.214-.434-.114-.133.085-.22.291-.115.434zM231.947 73.84a17.36 17.36 0 01-1.465 2.415c-.1.139-.023.354.114.434.16.094.333.025.434-.114a17.36 17.36 0 001.465-2.414c.076-.154.041-.344-.114-.434-.14-.082-.356-.04-.434.114zM236.568 78.676a15.56 15.56 0 01-1.724 1.237c-.141.087-.208.287-.114.433.091.142.283.208.434.114.648-.401 1.267-.85 1.852-1.336.129-.107.119-.34 0-.45-.135-.124-.311-.112-.448.002zM211.415 96.998a10.206 10.206 0 001.563-1.368c.113-.122.129-.33 0-.449-.123-.113-.328-.13-.449 0a9.598 9.598 0 01-1.174 1.077c.023-.019.024-.019 0 0l-.053.04a9.64 9.64 0 01-.208.152c-.134.096-.212.28-.114.434.087.135.29.217.435.114zM217.827 99.052l-.766 1.575c-.073.149-.045.35.114.434.142.075.357.046.434-.114l.766-1.575c.073-.149.046-.35-.113-.434-.144-.074-.358-.045-.435.114zM231.265 98.447l.878 1.025c.108.126.339.12.449 0 .123-.134.115-.313 0-.448l-.878-1.025c-.109-.127-.339-.12-.449 0-.124.135-.116.314 0 .448zM236.312 94.095c.392.42.852.781 1.354 1.062.144.081.352.04.434-.114.078-.15.04-.347-.115-.434a5.585 5.585 0 01-.512-.327l-.082-.06-.036-.028c-.035-.026.061.048.002.002a5.502 5.502 0 01-.596-.55.32.32 0 00-.449 0 .326.326 0 000 .449z"
        fill="#575757"
      />
      <path
        d="M63.09 178.461s-2.77-5.867-7.969-2.683c-4.924 3.015-.182 10.14 9.216 12.176a2.953 2.953 0 002.77-.834c2.689-2.779 8.638-10.052 2.1-14.36-4.221-2.782-6.116 5.701-6.116 5.701z"
        fill="#F93700"
      />
      <path
        d="M25.88 217.206a.863.863 0 01-.988-.684c-.105-.537-2.494-13.188 6.054-15.992 1.817-.596 3.552-.427 5.155.502 4.918 2.851 6.853 12.046 6.933 12.436a.863.863 0 01-1.691.347c-.019-.088-1.86-8.831-6.11-11.292-1.175-.68-2.4-.795-3.75-.354-7.112 2.333-4.92 13.903-4.897 14.02a.863.863 0 01-.68 1.013l-.027.004z"
        fill="#C4C4C4"
      />
      <path
        d="M15.848 251.548l.371-36.555 23.352-4.25 13.86 37.015-35.168 5.803a2.077 2.077 0 01-2.415-2.013z"
        fill="#06050E"
      />
      <path
        d="M44.184 210.38l-4.612.363 13.86 37.015 4.515-.745a1.462 1.462 0 001.126-1.969l-14.89-34.664zM20.829 217.936a.863.863 0 01-.987-.684c-.106-.538-2.494-13.189 6.054-15.992 1.817-.597 3.552-.428 5.155.502 4.918 2.85 6.853 12.045 6.933 12.435a.862.862 0 01-.672 1.018.859.859 0 01-1.02-.671c-.017-.088-1.86-8.83-6.11-11.291-1.174-.68-2.4-.796-3.748-.354-7.113 2.333-4.92 13.902-4.898 14.019a.862.862 0 01-.68 1.013l-.027.005zM311.796 176.431c-.24 3.304-.497 6.607-.741 9.91-.056.753-.179 1.497.322 2.122.546.68 1.364.609 2.132.654 1.873.109 3.742.314 5.595.62.929.154 1.851.34 2.774.532.72.151 1.464-.111 1.846-.804.212-.385.237-.85.301-1.28a140.607 140.607 0 00.588-4.401 139.705 139.705 0 00.443-4.465c.038-.456.117-.938.098-1.396a1.746 1.746 0 00-1.326-1.637 29.049 29.049 0 00-2.437-.511 54.028 54.028 0 00-4.977-.589c-.753-.058-1.506-.105-2.259-.147-.685-.038-1.407-.165-1.941.4-.258.273-.378.616-.418.992-.025.236.211.441.419.441.247 0 .394-.204.419-.441.07-.668.777-.551 1.225-.526.669.036 1.338.076 2.006.122 1.481.104 2.962.241 4.431.459.781.116 1.558.252 2.33.419.368.08.743.154 1.104.264.24.073.442.255.534.503.126.337.026.76-.002 1.108a132.659 132.659 0 01-.383 4.091c-.196 1.788-.43 3.571-.702 5.348-.051.337-.059.717-.301.973a.811.811 0 01-.691.247c-.397-.048-.797-.171-1.191-.248a56.137 56.137 0 00-6.411-.882 54.883 54.883 0 00-1.279-.084c-.32-.017-.707.033-1.006-.107-.588-.275-.468-.931-.428-1.479.226-3.063.463-6.125.689-9.188l.075-1.019c.041-.567-.797-.564-.838-.001z"
        fill="#8A8888"
      />
      <path
        d="M317.395 186.003c.044.071.098.148.178.16.075.012.144-.037.204-.083 2.471-1.887 7.405-6.368 10.074-8.667a.437.437 0 00-.022-.676 2.994 2.994 0 01-.932-1.116c-.045-.091-.112-.202-.208-.187a.227.227 0 00-.097.051c-1.406 1.071-6.754 5.811-8.085 7.145a.396.396 0 01-.594-.034 28.092 28.092 0 01-1.577-2.184.392.392 0 00-.541-.129l-.386.242c-.201.126-.409.257-.547.455-.35.503 1.698 3.7 2.533 5.023z"
        fill="#000"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={88.9619}
          y1={197.163}
          x2={170.895}
          y2={115.719}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F3D56" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1={195.891}
          y1={216.256}
          x2={174.264}
          y2={102.772}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F3D56" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1={-28.8134}
          y1={179.353}
          x2={202.328}
          y2={156.111}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset={1} stopColor="#050506" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1={132.884}
          y1={61.5092}
          x2={135.684}
          y2={53.8635}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset={1} stopColor="#1A0F91" />
        </linearGradient>
      </defs>
    </svg>
  )
}

SellerNotAvailableSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
