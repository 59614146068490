import React from 'react'
import PropTypes from 'prop-types'
import { ArrowDownward } from '@material-ui/icons'
import { ArrowDownScroll } from '../style'
import { useDevices } from '@gojiraf/responsive'

export const ScrollToBottom = ({ showNotification, onClick, isMessageListScrolledToBottom }) => {
  const { isDesktop } = useDevices()
  const displayScrolltoBottom = !isMessageListScrolledToBottom || showNotification

  return (
    <ArrowDownScroll
      onClick={onClick}
      hidden={!displayScrolltoBottom}
      isDesktop={isDesktop}
      size={isDesktop ? 'medium' : 'small'}
      color="secondary"
    >
      <ArrowDownward />
    </ArrowDownScroll>
  )
}

ScrollToBottom.propTypes = {
  showNotification: PropTypes.bool,
  onClick: PropTypes.func,
  isMessageListScrolledToBottom: PropTypes.bool,
}
