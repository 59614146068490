import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

body {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(215, 215, 215, 1) transparent;
}

::-webkit-scrollbar {
    width: 8px; 
  }

::-webkit-scrollbar-thumb {
    background-color: rgba(215, 215, 215, 1); 
    border-radius: 6px;
  }

::-webkit-scrollbar-track {
    background-color: rgba(215, 215, 215, 0.3);
    border-radius: 6px;
  }

`
